import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//Actions
import { hideLoginModal, login } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";

//Icons
import { ReactComponent as ExitIcon } from "../../../assets/icons/svg/exit.svg";

//Sections
import LoginContent from "./LoginContent";

//TO DO: Auto open register modal if user not finished signup flow -> Auto open if not in private beta to invite page
const LoginModal = ({ login, hideLoginModal, auth: { modals } }) => {
  const onLoginModalClose = () => {
    hideLoginModal();
  };

  //Navigation per stage

  return (
    <Modal
      aria-labelledby="Login"
      aria-describedby="Login to thisPantry"
      open={modals.login}
      onClose={onLoginModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modals.login}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Paper
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto outline-none"
            elevation={0}
          >
            <div className="bg-backround rounded-lg min-h-[10vh] max-h-[85vh] w-[95vw] md:min-w-[600px] overflow-y-auto max-w-[450px] md:max-w-[30vw] shadow-sm ">
              <div className="w-full flex items-center justify-between flex-row top-0 p-4 fixed">
                <div></div>

                <IconButton
                  className="p-0 hover:bg-transparent w-[1rem]"
                  disableRipple
                  onClick={() => onLoginModalClose()}
                >
                  <ExitIcon className="h-[1rem]" />
                </IconButton>
              </div>
              <div className="px-4 sm:px-8 md:px-12 py-8 md:py-16">
                <LoginContent />
              </div>
            </div>
          </Paper>
        </div>
      </Fade>
    </Modal>
  );
};

LoginModal.propTypes = {
  register: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  hideLoginModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  register: state.register,
  auth: state.auth,
});

export default connect(mapStateToProps, { hideLoginModal, setSignupStage })(LoginModal);
