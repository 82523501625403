import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Defaults
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as StarIcon } from "../../../assets/icons/svg/star-solid.svg";
import { ReactComponent as TimesCookedIcon } from "../../../assets/icons/svg/kitchen-dashboard.svg";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-regular-svg-icons";

//MUI
import { Typography, IconButton } from "@mui/material";
import { shortInteractionCount } from "../../../utils/post";

//Utils
import { shortTimeSince } from "../../../utils/date";

const RecipeReview = ({ review }) => {
  //TO DO:
  //Options modal
  return (
    <div className="mt-2">
      <div className="flex justify-between items-center">
        <div className="flex flex-row items-center">
          <Link to={`/${review.user.username}`}>
            <div className="flex flex-row items-center">
              {review.user.picture !== "" ? (
                <img
                  src={review.user.picture}
                  alt={review.user.username}
                  className="w-[1.5rem] h-[1.5rem] object-cover rounded-[50%] border border-separator"
                />
              ) : (
                <DefaultPFP className="h-[1.5rem] w-[1.5rem]" />
              )}
              <Typography variant="subtitle2" className="mx-2 font-normal">
                @{review.user.username}
              </Typography>
            </div>
          </Link>
          <Typography variant="caption" className="text-secondaryText italic">
            Made {shortInteractionCount(review.timesCooked)} times
          </Typography>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <Typography variant="caption" className="text-secondaryText">
              {shortTimeSince(review.date)}
            </Typography>
          </div>
          <IconButton
            size="small"
            className="text-primaryText hover:bg-transparent hover:text-secondaryText"
            disableRipple
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </IconButton>
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="flex flex-row items-center mt-1 justify-between">
          <div className="flex flex-row items-center">
            <StarIcon className="h-[1rem] fill-rating mr-2" />
            <Typography variant="h6" className="font-normal">
              {review.rating}
            </Typography>
          </div>
          {review.public === false && (
            <Typography variant="subtitle2" className="text-secondaryText italic font-normal mt-2">
              Private Review
            </Typography>
          )}
        </div>
        {review.comment && review.comment !== "" && (
          <div className="flex-grow break-words mt-1">
            <Typography variant="body2">{review.comment}</Typography>
          </div>
        )}
        <div className="flex flex-row items-center mt-2">
          <IconButton size="small" disableRipple className="hover:bg-transparent">
            <FontAwesomeIcon icon={faThumbsUp} className="hover:text-mainGreen" />
          </IconButton>
          <Typography variant="subtitle1" className="text-separator mx-2">
            /
          </Typography>
          <IconButton size="small" disableRipple className="hover:bg-transparent ">
            <FontAwesomeIcon icon={faThumbsDown} className="hover:text-rose-700" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default RecipeReview;
