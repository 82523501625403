import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
//Actions
import { getCommentsForPost, commentOnPost, closePostComments } from "../../../actions/feed";
import { setAlert } from "../../../actions/alert";

//Components
import Spinner from "../../layout/Spinner";
import PostComment from "./PostComment";

const PostCommentModal = ({
  postid,
  getCommentsForPost,
  closePostComments,
  commentOnPost,
  commentCount,
  setCommentCount,
  feed: { comments, commentsLoading, commentsError },
  setAlert,
  scrollToTop,
}) => {
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    getCommentsForPost(postid);

    return () => {
      //Reset comments state
      closePostComments();
    };
  }, []);

  const onCommentOnPost = async (e) => {
    e.preventDefault();
    //Check if successful, send alerts
    const commented = await commentOnPost(postid, commentText);

    if (commented) {
      //Clear comment
      setCommentText("");
      //Scroll container to top
      scrollToTop();
      //Set comment count
      setCommentCount(commentCount + 1);
      setAlert("Commented on post", "success");
    } else {
      setAlert("Uh oh. An error occurred. Refresh and try again.", "error");
    }
  };

  return (
    <div className="h-[85vh] flex flex-col border-t border-separator pt-4 mt-2">
      <div className="flex-1 overflow-auto">
        {commentsLoading ? (
          <Spinner />
        ) : commentsError ? (
          <Typography>An error has occurred. Please refresh the page and try again.</Typography>
        ) : (
          <Fragment>
            {comments.length > 0 ? (
              comments.map((comment, i) => <PostComment comment={comment} postid={postid} key={i} />)
            ) : (
              <div className="flex items-start justify-center h-[60vh]">
                <Typography className="my-4 text-secondaryText font-normal" variant="subtitle1">
                  No comments yet...
                </Typography>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="mb-4 md:mb-0 w-full p-4 bg-white">
        <form onSubmit={onCommentOnPost}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Add a comment"
            multiline
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button className="text-mainGreen" type="submit" variant="text">
                    Post
                  </Button>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </form>
      </div>
    </div>
  );
};

PostCommentModal.propTypes = {
  getCommentsForPost: PropTypes.func.isRequired,
  commentOnPost: PropTypes.func.isRequired,
  closePostComments: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  feed: state.feed,
});

export default connect(mapStateToProps, { getCommentsForPost, closePostComments, commentOnPost, setAlert })(
  PostCommentModal
);
