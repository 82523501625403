import React from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

///Components
import Spinner from "../../../layout/Spinner";
import RecipeCard from "../../../recipes/card/RecipeCard";
import Button from "@mui/material/Button";

//Icons
import { ReactComponent as LinkIcon } from "../../../../assets/icons/svg/link.svg";
import { ReactComponent as NextIcon } from "../../../../assets/icons/svg/chevron-right.svg";

//MUI
import Typography from "@mui/material/Typography";

//shadcn
import { ScrollArea, ScrollBar } from "../../../ui/scroll-area";
import { Separator } from "../../../ui/separator";

const MainSection = ({ cookbook, reference, title, standaloneURL }) => {
  return (
    <div className="w-full">
      <div className="mt-3">
        <div className="mb-4 w-fit">
          <Link to={`/cookbook/${standaloneURL}`}>
            <div className="flex flex-row items-center">
              <div>
                <div className="flex flex-row items-center">
                  <Typography variant="h6" className="font-normal">
                    {title}
                  </Typography>
                  <LinkIcon className="h-[0.75rem] fill-secondaryText ml-2" />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <ScrollArea className="w-full">
          <div className="flex w-max space-x-4">
            {cookbook.results[reference].loading ? (
              <div className="mx-8">
                <Spinner />
              </div>
            ) : cookbook.results[reference].error ? (
              <div className="mx-4 my-8">
                <Typography variant="h6" className="text-secondaryText font-light">
                  Error loading recipes, please refresh and try again.
                </Typography>
              </div>
            ) : cookbook.results[reference].recipes && cookbook.results[reference].recipes.length > 0 ? (
              <>
                {cookbook.results[reference].recipes.map((recipe, i) => {
                  return (
                    <div key={i} className="flex-none w-[300px] h-[300px] relative">
                      <RecipeCard recipeid={recipe.id} errorType="deleted" />
                    </div>
                  );
                })}
                {!cookbook.results[reference].isEndOfFeed && (
                  <Link to={`/cookbook/${standaloneURL}`} className="w-full h-full">
                    <div className="w-[200px] h-[300px] relative flex items-center justify-center">
                      <Button className="p-0">
                        <div>
                          <div className="p-4 bg-mainGreen rounded-full flex items-center justify-center gap-2">
                            <Typography variant="subtitle1" className="text-white font-normal">
                              View More
                            </Typography>
                            <NextIcon className="h-4 fill-white" />
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Link>
                )}
              </>
            ) : (
              <div className="mx-4 my-8">
                <Typography variant="h6" className="text-secondaryText font-light">
                  No recipes found
                </Typography>
              </div>
            )}
          </div>
          <div className="my-4 cursor-pointer">
            <ScrollBar orientation="horizontal" />
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

MainSection.propTypes = {
  cookbook: PropTypes.object.isRequired,
  reference: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  standaloneURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cookbook: state.cookbook,
});

export default connect(mapStateToProps, {})(MainSection);
