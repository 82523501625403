import React from "react";

//Components
import ForYouGridManager from "./ForYouGridManager";

const ForYouGrid = ({ posts, columns }) => {
  return (
    <div className="grid auto-fill-minmax-250 md:auto-fill-minmax-300 gap-2">
      {posts.map((post) => (
        <ForYouGridManager key={post.id} post={post} />
      ))}
    </div>
  );
};

export default ForYouGrid;
