import React from "react";
import { styled } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

const PREFIX = "GridSkeleton";

const classes = {
  root: `${PREFIX}-root`,
  skeleton: `${PREFIX}-skeleton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    paddingBottom: "100%", // This makes the height equal to the width
    position: "relative",
  },

  [`& .${classes.skeleton}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "0.5rem",
  },
}));

const GridSkeleton = () => {
  return (
    <Root className={classes.root}>
      <Skeleton variant="rectangular" className={classes.skeleton} />
    </Root>
  );
};

export default GridSkeleton;
