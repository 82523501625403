import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

//MUI
import Typography from "@mui/material/Typography";

//Images
import { ReactComponent as Icon } from "../landing/icon.svg";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = ({ auth: { isAuthenticated }, navMargin = true }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const location = useLocation();

  const onNavigation = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className={`flex flex-col w-full ${
        isAuthenticated && navMargin ? "md:ml-[5rem] md:w-[calc(100%-5rem)]" : ""
      } bg-mainGreen-900 px-4 py-8 md:px-10 gap-8 `}
    >
      <div className="flex flex-col w-1/2 md:flex-row gap-2 md:gap-8 flex-1">
        <div className="my-2 text-background">
          <Typography variant="h6" className="mb-2">
            Join
          </Typography>
          <div className="flex flex-col gap-1 text-secondaryText-100">
            {!isAuthenticated && (
              <>
                <Link to="/signup" onClick={() => onNavigation()}>
                  <Typography variant="body2" className=" hover:font-medium">
                    Sign Up
                  </Typography>
                </Link>
                <Link to="/login" onClick={() => onNavigation()}>
                  <Typography variant="body2" className=" hover:font-medium">
                    Login
                  </Typography>
                </Link>
              </>
            )}
            <Link to="/plus" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                thisPantry+
              </Typography>
            </Link>
          </div>
        </div>
        <div className="my-2 text-background">
          <Typography variant="h6" className="mb-2">
            Heat
          </Typography>
          <div className="flex flex-col gap-1 text-secondaryText-100">
            <Link to="/trending/recipes" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Trending Recipes
              </Typography>
            </Link>
            <Link to="/trending/users" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Trending Users
              </Typography>
            </Link>
          </div>
        </div>
        <div className="my-2 text-background">
          <Typography variant="h6" className="mb-2">
            Help
          </Typography>

          <div className="flex flex-col gap-1 text-secondaryText-100">
            <Link to="/contact" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Contact Us
              </Typography>
            </Link>
            <Link to="/privacy" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Privacy
              </Typography>
            </Link>
            <Link to="/terms" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Terms
              </Typography>
            </Link>
            <Link to="/cookies" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Cookies
              </Typography>
            </Link>
            <Link to="/sitemap" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Sitemap
              </Typography>
            </Link>
          </div>
        </div>
      </div>
      <div className="h-full my-auto">
        <div>
          <div className="inline-block">
            <Link to="/" className="h-full flex items-center no-underline" onClick={() => onNavigation()}>
              <div className="h-full flex items-center">
                <Icon className="fill-mainGreen h-[3rem]"></Icon>
              </div>

              <div className="text-mainGreen flex flex-col ml-2 h-full">
                <Typography className="font-light text-2xl leading-[0.8]">this</Typography>
                <Typography className="font-semibold text-[2rem] leading-[0.7]">Pantry</Typography>
              </div>
            </Link>
            <div className="w-full flex items-center justify-between my-4 text-background">
              <a href="https://www.instagram.com/thispantry" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="h-[1.5rem] w-auto hover:text-[#E1306C]" />
              </a>
              <a href="https://www.facebook.com/thispantry" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="h-[1.5rem] w-auto hover:text-[#1877F2]" />
              </a>
              <a href="https://www.twitter.com/thispantry" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faXTwitter} className="h-[1.5rem] w-auto hover:text-[#000000]" />
              </a>
              <a href="https://youtube.com/@thispantry" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} className="h-[1.5rem] w-auto hover:text-[#FF0000]" />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <Typography variant="caption" className="text-secondaryText-200 leading-tight">
              Copyright &copy; 2024
            </Typography>
            <Typography variant="caption" className="text-secondaryText-200 leading-tight">
              thisPantry Inc.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Footer);
