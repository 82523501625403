import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { preventWidows } from "../../utils/text";
import { motion, useInView } from "framer-motion";

//Actions
import { hideRegisterModal } from "../../actions/auth";
import { setSignupStage, abilityNext } from "../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

//Icons
import { ReactComponent as NewFeaturesIcon } from "../../assets/icons/svg/tag-light.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/svg/chart-line.svg";
import { ReactComponent as PantryIcon } from "../../assets/icons/svg/carrot.svg";
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef-sharp-light.svg";
import { ReactComponent as RewardsIcon } from "../../assets/icons/svg/cake-light.svg";
import { ReactComponent as DevIcon } from "../../assets/icons/svg/phone-light.svg";
import { ReactComponent as PriceIcon } from "../../assets/icons/svg/badge-check-light.svg";

//Screenshots
import InsightsScreenshot1 from "../landing/img/savings/dashboard.png";
import InsightsScreenshot2 from "../landing/img/savings/highlight.png";
import PantryScreenshot1 from "../landing/img/pantry/1/receipt_scan.png";
import PantryScreenshot2 from "../landing/img/pantry/2/ui.png";

//Images
import PlusImage from "./res/plusmodal/plusmodal4.jpg";

const PlusModal = ({ handleClose }) => {
  return (
    <div className="pb-8">
      <div className="w-full">
        <div className="w-full aspect-[3/2] relative overflow-hidden mb-8 min-h-[500px] flex items-end">
          <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${PlusImage})` }}></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
          <div className="flex flex-col items-start justify-end h-full px-4 py-8 md:px-8 md:py-12 relative z-10 ">
            <Typography variant="h3" className="mb-2 text-white font-medium text-3xl md:text-4xl lg:text-5xl">
              {preventWidows("Be the first to experience the future")}
            </Typography>
            <Typography
              variant="subtitle1"
              className="leading-snug text-secondaryText-100 text-sm sm:text-base md:text-lg"
            >
              {preventWidows("Unlock early access to our premium beta test for $12/month and keep the price forever.")}
            </Typography>
            <Link to="/plus/join" className="w-full md:w-auto">
              <Button variant="contained" color="primary" className="mt-4 w-full md:w-auto">
                Join thisPantry+ Early Access
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 mb-12 pt-4">
        <FeatureItem
          icon={<ChefIcon className="h-6 fill-mainGreen" />}
          title="Generate Custom Recipes"
          description="Personalized recipes that match your pantry items, helping you make the most of what you have."
        />
        <FeatureItem
          icon={<NewFeaturesIcon className="h-6 fill-mainGreen" />}
          title="Exclusive Access to New Features"
          description="Get the inside scoop and be the first to try out new tools and functionalities before they're released to the public."
        />
        <FeatureItem
          icon={<DevIcon className="h-6 fill-mainGreen" />}
          title="Direct Line to Developers"
          description="Have a say in how thisPantry evolves by giving feedback directly to the team. Your input shapes the features you'll use every day."
        />
        <FeatureItem
          icon={<PriceIcon className="h-6 fill-mainGreen" />}
          title="Lifetime Price Guarantee"
          description="Lock in your subscription price forever—no future price hikes, ever."
        />
      </div>

      <Divider className="my-8 mx-4 md:mx-8" />

      <div className="mb-[7rem] mx-4 md:mx-8">
        <div className="mb-4">
          <Typography variant="h6" className="text-primaryText">
            Coming Soon to thisPantry<span className="text-mainGreen font-medium">+</span>
          </Typography>
          <Typography variant="subtitle2" className="text-secondaryText font-normal">
            Features that will be available to thisPantry+ members, coming Fall 2024.
          </Typography>
        </div>

        <div className="flex flex-col gap-12">
          <ComingSoonFeature
            icon={<InsightsIcon className="h-6 fill-mainGreen" />}
            title="Insights"
            description="Track your cooking habits, kitchen efficiency, and see how you're saving money over time."
            bulletPoints={[
              "Easily track your grocery and restaurant spending.",
              "Stay on top of your nutritional macros and see how you're doing over time.",
              "Monitor your food waste and save money by reducing spoilage.",
            ]}
            screenshots={[InsightsScreenshot1, InsightsScreenshot2]}
          />
          <ComingSoonFeature
            icon={<PantryIcon className="h-6 fill-mainGreen" />}
            title="Pantry"
            description="Your pantry, fridge, freezer, all digitized to keep you up to date in real-time."
            bulletPoints={[
              "Automatically track all of your expiration dates",
              "Get tailored recipes based on what you have and what you like, before your ingredients expire.",
              "Easily add ingredients by scanning receipts, barcodes, or by voice.",
              "Never miss a Beet! 😜",
            ]}
            screenshots={[PantryScreenshot1, PantryScreenshot2]}
            isReversed={true}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full border-t border-separator bg-background p-4 flex flex-col rounded-b-lg z-20">
        <Link to="/plus/join">
          <Button size="large" variant="contained" color="primary" fullWidth disableElevation>
            Join thisPantry+ Early Access
          </Button>
        </Link>
        <div className="w-full flex items-center justify-center">
          <Button
            className="text-secondaryText hover:bg-transparent"
            disableElevation
            disableRipple
            onClick={() => handleClose()}
          >
            No thanks, I'll wait
          </Button>
        </div>
      </div>
    </div>
  );
};

const FeatureItem = ({ icon, title, description }) => (
  <div className="flex items-start gap-4 mx-4 md:mx-8">
    <div>
      <div className="flex flex-row items-center gap-4 mb-2">
        <div>{icon}</div>
        <Typography variant="h6" className="leading-none">
          {preventWidows(title)}
        </Typography>
      </div>

      <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
        {description}
      </Typography>
    </div>
  </div>
);

const ComingSoonFeature = ({ icon, title, description, bulletPoints, screenshots, isReversed = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "-100px 0px",
  });

  return (
    <div ref={ref} className={`flex flex-col items-start md:items-center gap-8`}>
      <div className="w-full h-[350px] sm:h-[400px] md:h-[500px] flex justify-center items-center">
        <div className="relative h-full flex justify-center items-center gap-2">
          <motion.img
            src={screenshots[0]}
            alt={`${title} screenshot 1`}
            className="h-full w-auto max-w-[45%] object-contain relative z-10 drop-shadow-lg"
            style={{ transform: "translateX(-10%)" }}
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          />
          <motion.img
            src={screenshots[1]}
            alt={`${title} screenshot 2`}
            className="h-[90%] w-auto max-w-[40%] object-contain relative drop-shadow-lg"
            style={{ transform: "translateX(10%)" }}
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
      </div>
      <div className="flex items-start gap-4">
        <div>
          <div className="flex flex-row items-center gap-2">
            <div>{icon}</div>
            <Typography variant="h6" className="text-">
              {title}
            </Typography>
          </div>

          <Typography variant="subtitle1" className="text-secondaryText-500 leading-tight font-normal">
            {description}
          </Typography>
          <ul className="list-disc list-inside text-secondaryText mt-4">
            {bulletPoints.map((point, index) => (
              <li key={index} className="leading-tight text-sm mb-1">
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

PlusModal.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  abilityNext: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage, abilityNext })(PlusModal);
