import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as DefaultPFP } from "../../../../assets/default/profile_picture.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";

import FollowButton from "../../../profile/FollowButton";
import ImageCarousel from "../../../feed/types/components/ImageCarousel";

import { shortInteractionCount } from "../../../../utils/post";

const ProfileSearchObject = ({ profile, auth: { user }, border = true, padding = "p-4" }) => {
  console.log(profile);
  //TO DO: Add top recipe image as background with see through white backdrop? Move text around? Swipable through the images, showcase the recipes on swipe?
  return (
    <div className={`w-full ${border ? "border border-separator rounded-lg shadow-sm" : ""}`}>
      <div className="h-full flex flex-col justify-between">
        <div className={`flex flex-col items-start ${padding}`}>
          <div className="flex flex-row items-center gap-4">
            <div className="bg-background rounded-[50%]">
              {profile.picture !== "" ? (
                <Link to={`/${profile.username}`}>
                  <img
                    src={profile.picture}
                    alt={`${profile.username}`}
                    className="h-[3.5rem] w-[3.5rem] rounded-[50%] border border-separator object-cover"
                  />
                </Link>
              ) : (
                <Link to={`/${profile.username}`}>
                  <DefaultPFP className="h-[3.5rem] w-[3.5rem] rounded-[50%] border border-separator object-cover" />
                </Link>
              )}
            </div>
            <Link to={`/${profile.username}`} className="flex flex-col items-start h-full">
              <Typography variant="subtitle1" className="font-normal text-center leading-none">
                {profile.name}
              </Typography>
              <div className="flex flex-row items-center gap-1">
                <Typography
                  variant="subtitle1"
                  className="text-mainGreen text-center font-medium leading-none mt-1"
                >{`@${profile.username}`}</Typography>
                {profile.isPlus && <PlusIcon className="h-4 fill-mainGreen mt-1" />}
              </div>
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row items-center gap-1">
                  <Typography variant="caption" className="text-primaryText leading-none mt-2">
                    {shortInteractionCount(profile.followers)}
                  </Typography>
                  <Typography variant="caption" className="text-secondaryText leading-none mt-2">
                    followers
                  </Typography>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <Typography variant="caption" className="text-primaryText leading-none mt-2">
                    {shortInteractionCount(profile.following)}
                  </Typography>
                  <Typography variant="caption" className="text-secondaryText leading-none mt-2">
                    following
                  </Typography>
                </div>
              </div>
            </Link>
          </div>

          <Typography
            variant="caption"
            className="text-primaryText overflow-hidden text-ellipsis break-words w-full my-4 pl-[4.5rem]"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              height: "2.4em", // Adjust this value to match 2 lines of text
              lineHeight: "1.2em", // Adjust this value to match your font size
            }}
          >
            {profile.bio || "\u00A0"}
          </Typography>

          <div className="flex flex-col items-center w-full">
            <div className="flex flex-row gap-1 w-full relative">
              {profile?.topRecipes.length > 0 ? (
                <>
                  {[0, 1, 2].map((index) => (
                    <div key={index} className="w-1/3 aspect-square relative">
                      {profile.topRecipes && profile.topRecipes[index] && (
                        <Link
                          to={`/${profile.username}/${profile.topRecipes[index].url}`}
                          className="w-full h-full block"
                        >
                          <div className="relative w-full h-full">
                            <ImageCarousel
                              data={profile.topRecipes[index].data}
                              showEmpty={true}
                              showDots={false}
                              rootStyles="rounded-sm"
                            />
                            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-stone-900 opacity-10 pointer-events-none z-[1] rounded-sm"></div>
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col justify-center w-full aspect-[3/1]">
                  <Link to={`/${profile.username}`}>
                    <Typography
                      variant="subtitle2"
                      className="text-primaryText font-normal text-center"
                    >{`@${profile.username}`}</Typography>
                  </Link>

                  <Typography
                    variant="caption"
                    className="text-secondaryText text-center"
                  >{` hasn't shared any recipes yet`}</Typography>
                </div>
              )}
            </div>
          </div>
          <div className="w-full mt-4">
            {user._id !== profile._id ? (
              <FollowButton isFollowing={profile.isFollowing} userid={profile._id} fullWidth={true} />
            ) : (
              <Button variant="outlined" className="w-full border-separator hover:border-secondaryText">
                View My Profile
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileSearchObject.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileSearchObject);
