// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import registerReducer from "./reducers/register";
import profileReducer from "./reducers/profile";
import feedReducer from "./reducers/feed";
import recipeReducer from "./reducers/recipe";
import walkthroughReducer from "./reducers/walkthrough";
import updatesReducer from "./reducers/updates";
import pantryReducer from "./reducers/pantry";
import searchReducer from "./reducers/search";
import alertReducer from "./reducers/alert";
import mediaReducer from "./reducers/media";
import cookbookReducer from "./reducers/cookbook";
import reviewReducer from "./reducers/review";
import notificationsReducer from "./reducers/notifications";
import navigationReducer from "./reducers/navigation";
import plusReducer from "./reducers/plus";

const store = configureStore({
  reducer: {
    auth: authReducer,
    register: registerReducer,
    profile: profileReducer,
    feed: feedReducer,
    recipe: recipeReducer,
    walkthrough: walkthroughReducer,
    updates: updatesReducer,
    pantry: pantryReducer,
    search: searchReducer,
    alert: alertReducer,
    media: mediaReducer,
    cookbook: cookbookReducer,
    review: reviewReducer,
    notifications: notificationsReducer,
    navigation: navigationReducer,
    plus: plusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
