import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createSection, fetchExternalRecipe, fetchGeneratedRecipe } from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

import { Typography, TextField, ButtonGroup, Button } from "@mui/material";
import SelectableButtonGroup from "../../../layout/SelectableButtonGroup";
import Spinner from "../../../layout/Spinner";

import { Tabs, TabsList, TabsTrigger } from "../../../ui/tabs";
import { togglePlusUpgradeModal } from "../../../../actions/plus";

//TO DO:
//Verify section title length
//Verify section title uniqueness
//Verify section title is not empty
//Verify section before is not empty (if not first section), don't even show modal if preceding section is empty

//Future:
//Search other recipes, add as a section(s) -> debounce and search w/ 1s delay?
//Set "hasSearched" to expand modal?
//Generate section from prompt

//TO DO FIRST THING
//Make buttons from addType
//Search recipes to add
//Source type -> generated/imported from/inspired by/[LINK TO OTHER RECIPE eg, from Casserole by @username]...
//For search/import, detect if text is url, change type based on that. Search opens up to cards that open to modals, action = "Add as section(s)"
//Search modal also comes up from bottom? Eg, showing search results, maybe new search box?
const AddRecipeSectionModal = ({
  setAlert,
  createSection,
  handleModalClose,
  fetchExternalRecipe,
  fetchGeneratedRecipe,
  setRecipeFields,
  initialAddType = "add",
  initialAddPrompt = "",
  plus,
  togglePlusUpgradeModal,
}) => {
  const [title, setTitle] = useState(initialAddPrompt ? initialAddPrompt : "");
  const [importLoading, setImportLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [addType, setAddType] = useState(initialAddType);

  const [label, setLabel] = useState("Section Title");
  const [helperText, setHelperText] = useState("Add a title for a new recipe section");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addType === "add") {
      handleAddSection();
      return;
    }

    if (addType === "generate") {
      handleGenerateRecipe();
      return;
    }

    if (addType === "import") {
      handleExternalRecipe();
      return;
    }
  };

  const handleAddSection = (e) => {
    if (title === "") {
      setAlert("Section title cannot be empty", "warning");
      return;
    }

    if (title.length > 50) {
      setAlert("Section title cannot be longer than 50 characters", "warning");
    }

    let added = createSection(title);

    if (added) {
      setTitle("");
      handleModalClose();
    }
  };

  const handleTitleChange = (e) => {
    let title = e.target.value;

    /*
    if (title.length > 50) {
      return;
    }*/
    setTitle(e.target.value);
  };

  //TODO: Dispatch reporting action
  const handleGenerateRecipe = async () => {
    if (plus.isPlus) {
      if (title.trim() === "") {
        setAlert("Describe the recipe you want to generate", "warning");
        return;
      }

      setGenerateLoading(true);
      const recipeData = await fetchGeneratedRecipe(title);

      if (recipeData.error) {
        //Rate limit
        if (recipeData.error.code && recipeData.error.code === 429) {
          setAlert("The oven’s too hot right now! Let it cool down, and try again in a few.", "error");
        } else {
          setAlert("Could not generate recipe. Please try again.", "error");
        }
        setGenerateLoading(false);
      } else {
        setRecipeFields(recipeData);

        setAlert(recipeData.name ? `Generated ${recipeData.name}` : "Generated Recipe", "success");

        handleModalClose();
      }
    } else {
      togglePlusUpgradeModal(true);
    }
  };

  const handleExternalRecipe = async () => {
    setImportLoading(true);
    const recipeData = await fetchExternalRecipe(title);

    if (recipeData.error) {
      //Rate limit
      if (recipeData.error.code && recipeData.error.code === 429) {
        setAlert("Slow down! Too many requests. Try again soon.", "error");
      } else if (recipeData.error.code && recipeData.error.code === 404) {
        setAlert(`Could not find an importable recipe at ${title}`, "warning");
      } else {
        setAlert("Could not import recipe. Refresh the page and try again.", "error");
      }
      setImportLoading(false);
    } else {
      setRecipeFields(recipeData);

      setAlert(recipeData.name ? `Imported ${recipeData.name}` : "Imported Recipe", "success");

      //Notify user to check ingredients

      if (recipeData.sections && recipeData.sections.length > 1) {
        for (let i = 1; i < recipeData.sections.length; i++) {
          if (recipeData.sections[i - 1].ingredients == recipeData.sections[i].ingredients) {
            setAlert(
              "All ingredients were copied into all imported sections. Please review and verify ingredients in each section are only for that section.",
              "warning",
              20000
            );
            break;
          }
        }
      }
      setImportLoading(false);
      handleModalClose();
    }
  };

  const handleSelectSearchImport = () => {
    setAddType("import");
    setHelperText("Search for a recipe or import from a URL");
    setLabel("Import Recipe");
    //Pass text in textbox to other modal
    //Open search modal
    //
  };

  const handleSelectAddSection = () => {
    setAddType("add");
    setHelperText("Add a title for a new recipe section");
    setLabel("Section Title");
  };

  const handleSelectGenerate = () => {
    setAddType("generate");
    setHelperText("Generate a recipe section from a prompt with thisPantry Plus");
    setLabel("Generate Recipe");
  };

  const handleTabChange = (value) => {
    if (value === "import") {
      handleSelectSearchImport();
    }

    if (value === "add") {
      handleSelectAddSection();
    }

    if (value === "generate") {
      handleSelectGenerate();
    }
  };

  useEffect(() => {
    switch (initialAddType) {
      case "add": {
        handleSelectAddSection();
        return;
      }
      case "import": {
        handleSelectSearchImport();
        return;
      }
      case "generate": {
        handleSelectGenerate();
        return;
      }
    }
  }, [initialAddType]);

  return (
    <div className="mx-4 mb-4">
      {importLoading || generateLoading ? (
        <div className="flex flex-col items-center mb-8">
          <Spinner />
          <div>
            <Typography variant="subtitle1" className="text-secondaryText">
              Preheating the oven...
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <div className="w-full">
              <Tabs value={addType} onValueChange={(value) => handleTabChange(value)} className="w-full">
                <TabsList className="w-full border border-separator p-0">
                  <TabsTrigger value="add" className="w-full h-full">
                    Add
                  </TabsTrigger>
                  <TabsTrigger value="import" className="w-full h-full">
                    Import
                  </TabsTrigger>
                  <TabsTrigger value="generate" className="w-full h-full">
                    Generate
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>

          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              id="section-title"
              label={label}
              variant="outlined"
              fullWidth
              onChange={(e) => handleTitleChange(e)}
              value={title}
              autoComplete="off"
              helperText={helperText}
            />
            <div className="w-full flex justify-end mt-4">
              <div>
                {addType === "add" && (
                  <Button variant="contained" disableRipple disableElevation onClick={(e) => handleAddSection(e)}>
                    Add Section
                  </Button>
                )}
                {addType === "import" && (
                  <Button variant="contained" disableRipple disableElevation onClick={() => handleExternalRecipe()}>
                    Import
                  </Button>
                )}
                {addType === "generate" && (
                  <Button variant="contained" disableRipple disableElevation onClick={() => handleGenerateRecipe()}>
                    Generate
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

AddRecipeSectionModal.propTypes = {
  createSection: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  fetchExternalRecipe: PropTypes.func.isRequired,
  fetchGeneratedRecipe: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  plus: state.plus,
});

export default connect(mapStateToProps, {
  createSection,
  setAlert,
  fetchExternalRecipe,
  fetchGeneratedRecipe,
  togglePlusUpgradeModal,
})(AddRecipeSectionModal);
