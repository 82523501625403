import { Typography } from "@mui/material";
import React from "react";

export const Expenses = () => {
  return (
    <div className="w-full h-full border border-separator rounded-md p-4">
      <Typography>Expenses</Typography>
    </div>
  );
};

export default Expenses;
