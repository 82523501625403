import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { signup, hideRegisterModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

//MUI styling
import { useMediaQuery } from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const BasicInfoStandalone = ({ signup, hideRegisterModal, setSignupStage, register: { errors }, topMargin = true }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  //State
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [dob, setDOB] = useState(dayjs());

  const [showPasswords, setShowPasswords] = useState(false);

  //Today for max date
  const today = new Date().toISOString().split("T")[0];

  const onCreateAccount = async () => {
    const user = await signup({
      username,
      name,
      email,
      password1,
      password2,
      dob,
    });
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword1 = (e) => {
    setPassword1(e.target.value);
  };
  const handlePassword2 = (e) => {
    setPassword2(e.target.value);
  };

  //TO DO:
  //Signup progress bar?
  return (
    <div className="h-full w-full">
      <div className="w-full h-full min-h-screen flex flex-col justify-between">
        <div className="py-2">
          <Button className="text-secondaryText hover:bg-transparent" disableRipple onClick={() => setSignupStage(-1)}>
            <Typography variant="caption">{"< Go Back"}</Typography>
          </Button>
        </div>

        <div className="text-initial flex items-center justify-center flex-col flex-1">
          <div className="flex flex-col w-full mb-4">
            <Typography variant={isSmallScreen ? "h5" : "h4"} className="font-bold text-mainGreen">
              Get started with some basics
            </Typography>

            <Typography variant={isSmallScreen ? "body2" : "subtitle2"} className="mt-1">
              Sign up for the private beta with some basic info
            </Typography>
          </div>

          <div className="w-full flex flex-col gap-4 mb-2">
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={username}
              onChange={handleUsername}
              inputProps={{ maxLength: 20 }}
              error={errors && !errors.initial.username.isValid}
              helperText={errors && !errors.initial.username.isValid ? errors.initial.username.error : ""}
            />
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={handleName}
              inputProps={{ maxLength: 100 }}
              error={errors && !errors.initial.name.isValid}
              helperText={errors && !errors.initial.name.isValid ? errors.initial.name.error : ""}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Birthday"
                value={dob}
                onChange={(newDate) => setDOB(newDate)}
                sx={{ backgroundColor: "white" }}
                slotProps={{
                  textField: {
                    error: !errors.initial.dob.isValid,
                    helperText: !errors.initial.dob.isValid ? errors.initial.dob.error : "",
                  },
                }}
                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                inputFormat="MM/DD/YYYY"
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmail}
              inputProps={{ maxLength: 254 }}
              error={errors && !errors.initial.email.isValid}
              helperText={errors && !errors.initial.email.isValid ? errors.initial.email.error : ""}
            />
            <TextField
              fullWidth
              label="Password"
              type={showPasswords ? "text" : "password"}
              variant="outlined"
              value={password1}
              onChange={handlePassword1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswords(!showPasswords)}
                      edge="end"
                      size="small"
                    >
                      <FontAwesomeIcon icon={showPasswords ? "eye-slash" : "eye"} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors && !errors.initial.password1.isValid}
              helperText={errors && !errors.initial.password1.isValid ? errors.initial.password1.error : ""}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              type={showPasswords ? "text" : "password"}
              variant="outlined"
              value={password2}
              onChange={handlePassword2}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswords(!showPasswords)}
                      edge="end"
                      size="small"
                    >
                      <FontAwesomeIcon icon={showPasswords ? "eye-slash" : "eye"} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors && !errors.initial.password2.isValid}
              helperText={errors && !errors.initial.password2.isValid ? errors.initial.password2.error : ""}
            />
          </div>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            size={isSmallScreen ? "medium" : "large"}
            className="my-2 bg-mainGreen text-background"
            onClick={() => onCreateAccount()}
            type="submit"
          >
            Create Account
          </Button>
        </div>
      </div>
    </div>
  );
};

BasicInfoStandalone.propTypes = {
  signup: PropTypes.func.isRequired,
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  register: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  register: state.register,
});

export default connect(mapStateToProps, {
  signup,
  hideRegisterModal,
  setSignupStage,
})(BasicInfoStandalone);
