//Import redux types
import {
  SET_REGISTER_ERRORS,
  SET_SIGNUP_STAGE,
  CLEAR_SIGNUP,
  SIGNUP_PREFERENCES_ERROR,
  SIGNUP_RESTRICTIONS_NEXT,
  SIGNUP_PREFERENCES_NEXT,
  SIGNUP_RESTRICTIONS_ERROR,
  SIGNUP_ABILITY_ERROR,
  SIGNUP_ABILITY_NEXT,
  LOGOUT,
} from "../actions/types";

//Initial state object
const initialState = {
  stage: -1,
  restrictions: {
    allergies: [],
    diets: [],
  },
  preferences: {
    notifications: null,
  },
  ability: -1,
  errors: {
    initial: {
      username: {
        isValid: true,
        error: "",
      },
      name: {
        isValid: true,
        error: "",
      },
      email: {
        isValid: true,
        error: "",
      },
      password1: {
        isValid: true,
        error: "",
      },
      password2: {
        isValid: true,
        error: "",
      },
      dob: {
        isValid: true,
        error: "",
      },
      general: {
        error: "",
      },
    },
    restrictions: {
      allergies: {
        isValid: true,
        error: "",
      },
      diets: {
        isValid: true,
        error: "",
      },
      general: {
        error: "",
      },
    },
    ability: {
      general: {
        error: "",
      },
    },
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case SET_REGISTER_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.stage]: {
            ...initialState.errors[payload.stage],
            ...payload.errors,
          },
        },
      };
    //API call error
    case SIGNUP_PREFERENCES_ERROR:
      return {
        ...state,
      };
    case SIGNUP_PREFERENCES_NEXT:
      return {
        ...state,
        stage: 3,
        preferences: {
          notifications: payload.notifications,
        },
      };
    //API call error
    case SIGNUP_RESTRICTIONS_ERROR:
      return {
        ...state,
      };
    case SIGNUP_RESTRICTIONS_NEXT:
      return {
        ...state,
        stage: 2,
        restrictions: {
          allergies: payload.allergies,
          diets: payload.diets,
        },
      };
    case SIGNUP_ABILITY_ERROR:
      return {};
    case SIGNUP_ABILITY_NEXT:
      return {
        ...state,
        stage: 5,
        ability: payload.ability,
      };
    case SET_SIGNUP_STAGE:
      return {
        ...state,
        stage: payload,
      };
    case CLEAR_SIGNUP:
      return initialState;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
