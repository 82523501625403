import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../layout/Footer";

const SubscriptionTerms = () => {
  return (
    <>
      <Helmet>
        <title>thisPantry - Subscription Terms</title>
      </Helmet>

      <div className="h-full overflow-y-scroll">
        <div className="px-8 my-[5rem] md:ml-[5rem] md:mt-0 md: py-8 lg:px-32 xl:px-64 min-h-[100vh]">
          <div>
            <Typography variant="h5">thisPantry+ Terms</Typography>
          </div>
          <div className="my-4">
            <div className="my-2">
              <Typography variant="body1">
                By subscribing to{" "}
                <Link to="/plus" className="text-mainGreen">
                  thisPantry Plus
                </Link>
                , you ("Subscriber") agree to the following terms and conditions. This agreement is between you and
                thisPantry Inc. ("Company", "we", or "us"), governing your use of the{" "}
                <Link to="/plus" className="text-mainGreen">
                  thisPantry Plus
                </Link>{" "}
                service.{" "}
              </Typography>
              <br></br>
              <Typography variant="body1">
                This agreement references and is subject to our{" "}
                <Link to="/terms" className="text-mainGreen">
                  Terms and Conditions
                </Link>
                . By subscribing, you acknowledge that you have read, understood, and agree to be bound by both this
                subscription agreement and the{" "}
                <Link to="/terms" className="text-mainGreen">
                  Terms and Conditions
                </Link>
                .{" "}
              </Typography>
            </div>
          </div>
          <div className="my-4">
            <Typography variant="h6">Subscription Terms</Typography>
            <div className="my-2">
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Subscription Plan:
                </Typography>
                <Typography variant="body1">
                  <Link to="/plus" className="text-mainGreen">
                    thisPantry Plus
                  </Link>{" "}
                  is a paid subscription service offered at $12 USD per month for early users, which is locked in for
                  life as long as the subscription is maintained without interruption. For new users, standard pricing
                  will apply.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Service Access:
                </Typography>
                <Typography variant="body1">
                  As a{" "}
                  <Link to="/plus" className="text-mainGreen">
                    thisPantry Plus
                  </Link>{" "}
                  subscriber, you gain access to exclusive features and additional premium functionalities as described
                  on our website.
                </Typography>
              </div>
            </div>
          </div>

          <div className="my-4">
            <Typography variant="h6">Payment Terms</Typography>
            <div className="my-2">
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Payment Method:
                </Typography>
                <Typography variant="body1">
                  You must provide a valid payment method at the time of subscription. By subscribing, you authorize us
                  to automatically charge the subscription fee to your payment method on a recurring monthly basis.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Billing Cycle:
                </Typography>
                <Typography variant="body1">
                  Subscription fees are billed monthly, starting from the date you activate your subscription. You will
                  be charged at the beginning of each billing cycle until you cancel your subscription.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  No Refunds:
                </Typography>
                <Typography variant="body1">
                  All subscription payments are non-refundable. Upon cancellation, your subscription will remain active
                  until the end of the current billing period, and no prorated refunds will be provided for unused
                  portions of your subscription.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Failed Payment:
                </Typography>
                <Typography variant="body1">
                  If payment fails, we will attempt to process the payment again. If payment remains unsuccessful, your
                  subscription may be suspended or terminated.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Early User Discount:
                </Typography>
                <Typography variant="body1">
                  Early users who subscribed at $12 USD per month will maintain this rate for life as long as the
                  subscription remains active. If you cancel and later resubscribe, the standard pricing will apply.
                </Typography>
              </div>
            </div>
          </div>

          <div className="my-4">
            <Typography variant="h6">Cancellation and Termination</Typography>
            <div className="my-2">
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Cancellation by Subscriber:
                </Typography>
                <Typography variant="body1">
                  You can cancel your{" "}
                  <Link to="/plus" className="text-mainGreen">
                    thisPantry Plus
                  </Link>{" "}
                  subscription at any time via your account settings. Upon cancellation, your subscription will remain
                  active until the end of the current billing cycle. No refunds or credits will be provided for unused
                  portions of the subscription.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Termination by thisPantry Inc.:
                </Typography>
                <Typography variant="body1">
                  We reserve the right to terminate your access to the service at our discretion, with or without cause,
                  including for non-payment. In cases where thisPantry terminates without cause, you will receive a
                  prorated refund for any unused portion of your subscription.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="subtitle1" className="font-normal">
                  Effect of Termination:
                </Typography>
                <Typography variant="body1">
                  Upon cancellation or termination, you will lose access to the premium features provided by thisPantry
                  Plus. If you were on the early user pricing plan and cancel your subscription, you will not be
                  eligible for the $12 USD per month pricing upon resubscription.
                </Typography>
              </div>
            </div>
          </div>

          <div className="my-4">
            <Typography variant="h6">Modifications to the Agreement</Typography>
            <div className="my-2">
              <Typography variant="body1">
                thisPantry Inc. reserves the right to modify this agreement at any time. We will notify you of any
                material changes via email or through the platform. Continued use of{" "}
                <Link to="/plus" className="text-mainGreen">
                  thisPantry Plus
                </Link>{" "}
                after such changes constitutes acceptance of the new terms. If you do not agree with the changes, you
                must cancel your subscription before the next billing cycle.
              </Typography>
            </div>
          </div>
          <div className="my-8">
            <Divider />
          </div>
          <div className="my-4">
            <Typography variant="body1">
              For any questions or further assistance, please contact us at{" "}
              <a href="mailto:hello@thispantry.com" className="text-mainGreen">
                hello@thispantry.com
              </a>{" "}
              or via the form{" "}
              <Link to="/contact" className="text-mainGreen">
                here
              </Link>
              .
            </Typography>
          </div>
          <div className="my-4">
            <Typography variant="body1">
              By <Link to="/plus/join">subscribing</Link>, you acknowledge that you have read and agreed to the above
              terms as well as our{" "}
              <Link to="/terms" className="text-mainGreen">
                Terms and Conditions
              </Link>
            </Typography>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
};

export default SubscriptionTerms;
