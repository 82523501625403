import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../api/api";

import { Typography, Button } from "@mui/material";

import { showRegisterModal } from "../../actions/auth";

const PREFIX = "FollowButton";

const classes = {
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    width: theme.spacing(12),
    height: "100%",
    //
  },
}));

const FollowButton = ({
  isFollowing,
  userid,
  isProfile = false,
  followUser,
  auth: { isAuthenticated },
  showRegisterModal,
  onFollowCallback = () => {},
  onUnfollowCallback = () => {},
  size = "medium",
  fullWidth = false,
}) => {
  const [followingHover, setFollowingHover] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [followVisible, setFollowVisible] = useState(isFollowing);

  const handleUnfollow = async () => {
    if (!isAuthenticated) {
      showRegisterModal();
      return;
    }

    //Follow user while handling current profile state
    setFollowLoading(true);
    setFollowVisible(false);

    try {
      const res = await api.post(`/profile/unfollow/`, { id: userid });
      setFollowLoading(false);
      onUnfollowCallback();
    } catch (error) {
      setFollowLoading(false);
      setFollowVisible(true);
    }
  };

  const handleFollow = async () => {
    if (!isAuthenticated) {
      showRegisterModal();
      return;
    }
    //Follow user while handling current profile state
    setFollowLoading(true);
    setFollowVisible(true);

    try {
      console.log(userid);
      const res = await api.post(`/profile/follow/`, { id: userid });
      setFollowLoading(false);
      onFollowCallback();
    } catch (error) {
      setFollowLoading(false);
      setFollowVisible(false);
    }
  };

  return (
    <>
      {followVisible ? (
        followingHover ? (
          <Button
            variant="outlined"
            color="danger"
            onMouseLeave={() => setFollowingHover(false)}
            onClick={handleUnfollow}
            className={` ${fullWidth ? "w-full" : "w-24"}`}
            disabled={followLoading}
            size={size}
            fullWidth={fullWidth}
          >
            Unfollow
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            onMouseOver={() => setFollowingHover(true)}
            onClick={handleUnfollow}
            className={` ${fullWidth ? "w-full" : "w-24"}`}
            disabled={followLoading}
            size={size}
            fullWidth={fullWidth}
          >
            Following
          </Button>
        )
      ) : (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disableRipple
          onClick={() => handleFollow()}
          className={` ${fullWidth ? "w-full" : "w-24"}`}
          disabled={followLoading}
          size={size}
          fullWidth={fullWidth}
        >
          +&nbsp;Follow
        </Button>
      )}
    </>
  );
};

FollowButton.propTypes = {
  auth: PropTypes.object.isRequired,
  followUser: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { showRegisterModal })(FollowButton);
