import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

//Components
import Spinner from "../layout/Spinner";
import LoginContent from "./login/LoginContent";
import Footer from "../layout/Footer";

//Utils
import { preventWidows } from "../../utils/text";

//Resources
import LoginImage from "./res/register.jpg";

const Login = ({ auth: { isAuthenticated, loading } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Login - thisPantry</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : isAuthenticated ? (
        <Navigate to="/"></Navigate>
      ) : (
        <div className="w-full flex flex-col justify-center">
          <div className="w-full mx-auto">
            <div className="w-full h-full flex flex-col md:flex-row">
              <div className="w-full flex-1 h-full min-h-screen relative overflow-hidden hidden md:flex items-end">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${LoginImage})` }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
                <div className="flex flex-col items-start justify-end h-full px-4 py-8 md:px-8 md:py-12 relative z-10 ">
                  <Typography variant="h2" className="mb-2 text-white font-medium text-3xl md:text-4xl lg:text-5xl">
                    {preventWidows("Let's get cookin'")}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="leading-snug text-secondaryText-100 text-sm sm:text-base md:text-lg"
                  >
                    {preventWidows("Be the first to experience the future of your own kitchen")}
                  </Typography>
                </div>
              </div>
              <div className="md:w-[45vw] lg:w-[40vw] xl:w-[30vw] h-screen flex justify-center px-4 sm:px-8 xl:px-12">
                <LoginContent />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

Login.propTypes = {
  auth: PropTypes.object.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Login);
