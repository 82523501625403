import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { Progress } from "../../ui/progress";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";

import RadialChart from "../../charts/RadialChart";
//import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
//Icons
import { Beef, Pizza, Flame, Wheat } from "lucide-react";
//Sample data
const calories = {
  name: "Calories",
  goal: 2000,
  value: 1823,
  unit: "kcal",
};

const protein = {
  name: "Protein",
  goal: 120,
  value: 90,
  unit: "g",
};

const carbs = {
  name: "Carbohydrates",
  goal: 250,
  value: 200,
  unit: "g",
};

const fat = {
  name: "Fat",
  goal: 65,
  value: 70,
  unit: "g",
};

const MacroItem = ({ macro: { name, value, goal, unit } }) => {
  return (
    <div className="w-full p-4 border border-separator flex-col gap-8 rounded-md bg-background">
      <div className="w-full flex flex-row justify-between items-center">
        <Typography variant="h6">{name}</Typography>
        {name === "Calories" && <Flame className="h-4 w-4 text-secondaryText" />}
        {name === "Protein" && <Beef className="h-4 w-4 text-secondaryText" />}
        {name === "Carbohydrates" && <Wheat className="h-4 w-4 text-secondaryText" />}
        {name === "Fat" && <Pizza className="h-4 w-4 text-secondaryText rotate-90" />}
      </div>
      <div className="mt-8">
        <div className="flex flex-col gap-4">
          <Typography variant="h5" className="font-light">
            {value}/{goal}
            {` ${unit}`}
          </Typography>
          <Progress value={(value / goal) * 100} />
        </div>

        <div className="mt-2">
          <Typography variant="caption">90% of your goal</Typography>
        </div>
      </div>
    </div>
  );
};

export const Macronutrients = () => {
  const [macrosType, setMacrosType] = useState("Eaten");
  return (
    <div className="flex flex-col border border-separator rounded-md shadow-sm">
      <div className="flex flex-col px-4 py-3 border-b border-separator">
        <Typography variant="h6" className="leading-none">
          Macronutrients
        </Typography>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="small" className="p-0 hover:bg-transparent">
                <Typography
                  variant="caption"
                  className="text-secondaryText leading-none italic hover:font-medium hover:text-primaryText"
                >
                  {macrosType}
                </Typography>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuLabel>View Macros</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked={macrosType === "Eaten"} onCheckedChange={() => setMacrosType("Eaten")}>
                Eaten
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                checked={macrosType === "Planned"}
                onCheckedChange={() => setMacrosType("Planned")}
              >
                Planned
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                checked={macrosType === "Remaining"}
                onCheckedChange={() => setMacrosType("Remaining")}
              >
                Remaining
              </DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-4 bg-neutral-50 px-2 py-3 rounded-b-md">
        <MacroItem macro={calories} />
        <MacroItem macro={protein} />
        <MacroItem macro={carbs} />
        <MacroItem macro={fat} />
      </div>
    </div>
  );
};

export default Macronutrients;
