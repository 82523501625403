import React from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import FollowButton from "../FollowButton";
import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/svg/leaf-solid.svg";
const UserListComponent = ({
  showFollow,
  user,
  onFollowCallback = () => {},
  onUnfollowCallback = () => {},
  handleModalClose = () => {},
}) => {
  return (
    <div className="w-full px-4 py-4 flex justify-between border-t border-separator">
      <Link to={`/${user.username}`} onClick={() => handleModalClose()}>
        <div className="flex items-center">
          <div className="flex items-center">
            {user && user.picture && user.picture !== "" ? (
              <div className="h-10 aspect-square">
                <img
                  src={user.picture}
                  alt={`@${user.username}}`}
                  className="w-full aspect-square rounded-[50%] object-cover border border-separator"
                />
              </div>
            ) : (
              <DefaultPFP className="h-10 aspect-square rounded-[50%] object-cover border border-separator" />
            )}
          </div>
          <div className="w-full ml-4">
            <Typography variant="body1" className="leading-none mb-1">
              {user.name}
            </Typography>
            <div className="flex flex-row items-center">
              <Typography
                vatiant="body2"
                className="text-mainGreen font-medium leading-snug"
              >{`@${user.username}`}</Typography>
              {user.isPlus && <PlusIcon className="h-[1rem] ml-1 fill-mainGreen" />}
            </div>
          </div>
        </div>
      </Link>

      {showFollow && (
        <div className="flex items-center">
          <FollowButton
            isFollowing={true}
            userid={user.id}
            onFollowCallback={onFollowCallback}
            onUnfollowCallback={onUnfollowCallback}
          />
        </div>
      )}
    </div>
  );
};

export default UserListComponent;
