import React, { useRef, useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Helmet from "react-helmet";

//Components
import ProfileNav from "./ProfileNav";
import Post from "../feed/Post";
import Spinner from "../layout/Spinner";
import ProfileFeedManager from "./ProfileFeedManager";
import FollowButton from "./FollowButton";
import ModularPostGrid from "../layout/modulargrid/ModularPostGrid";
import ProfilePinned from "./ProfilePinned";
import Footer from "../layout/Footer";
//Modals
import CustomModal from "../modal/CustomModal";
import ProfileOptionsModal from "./modals/ProfileOptionsModal";
import ProfileFollowersModal from "./modals/ProfileFollowersModal";
import ProfileFollowingModal from "./modals/ProfileFollowingModal";

//Resources
import DefaultPFP from "../../assets/default/profile_picture.svg";
import { ReactComponent as CreateIcon } from "../../assets/icons/svg/create.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/leaf-solid.svg";
//MUI
import { Typography, Button, IconButton, InputAdornment, useMediaQuery } from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faUserPen,
  faGear,
  faEllipsis,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

//Actions
import { getViewedProfile, resetViewedProfile } from "../../actions/profile";
import CannotFind from "../static/CannotFind";
import { showRegisterModal } from "../../actions/auth";

//Utility Functions
const commaFormatValue = (value) => {
  //Format number to the following format
  //0 - 9,999 -> Comma format
  //10,000 - 999,999 -> 10k - 999k
  //1,000,000 - 999,999,999 -> 1m - 999m

  if (value < 1000) {
    return value;
  } else if (value < 10000) {
    return value.toLocaleString(); // This will add a comma after the third digit
  } else if (value < 1000000) {
    return `${Math.floor((value / 1000) * 10) / 10}k`; // This will show the nearest 0.1 (rounded as floor)
  } else {
    return `${Math.floor((value / 1000000) * 10) / 10}m`; // This will show the nearest 0.1 (rounded as floor)
  }
};

const Profile = ({
  getViewedProfile,
  resetViewedProfile,
  auth: { isAuthenticated },
  profile: { viewedProfile, currentProfile, viewedLoading },
  showRegisterModal,
}) => {
  const { username } = useParams();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const [showPosts, setShowPosts] = useState(isAuthenticated ? true : false); //Default show recipes if not logged in, gets crawlers to log recipes for SEO

  //Modal state
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);

  const [followingCount, setFollowingCount] = useState(0);

  const onFollowCallback = () => {
    setFollowerCount(followerCount + 1);
  };

  const onUnfollowCallback = () => {
    setFollowerCount(followerCount - 1 > 0 ? followerCount - 1 : 0);
  };

  const handleShowFollowersModal = () => {
    if (!isAuthenticated) {
      showRegisterModal();
      return;
    }
    setShowFollowersModal(true);
  };

  const [showFollowingModal, setShowFollowingModal] = useState(false);

  const handleShowFollowingModal = () => {
    if (!isAuthenticated) {
      showRegisterModal();
      return;
    }
    setShowFollowingModal(true);
  };

  /*
    TO DO:

        - Handle no auth interaction
        - Footer
        - Error styling
        - Empty posts styling
        - Empty recipes styling
        - Mobile styling
        - Create button if it's your profile
        - No auth default to recipes, get recipe JSON for SEO
        - Hide create button if not your profile
        - Move create button next to options on smaller screens?
        - Accept no-auth fetch post requests, hide critical data but still display
        - Move post toggles above profile info on desktop, add pinned as an option, show a wider grid,
        maintain visibility of post toggle when scrolling
        - If posts
    */

  //Get profile
  const asyncGetProfile = async () => {
    const social = await getViewedProfile(username);
    setFollowerCount(social.followers);
    setFollowingCount(social.following);

    //Determine to show posts or recipes
    //Recipes first if not auth or if no post
    if (isAuthenticated) {
      if (viewedProfile && (viewedProfile?.pinned?.pinned.length > 0 || viewedProfile?.posts?.posts.length > 0)) {
        setShowPosts(true);
      } else {
        //Default to empty recipes if user hasn't posted anything
        setShowPosts(false);
      }
    } else {
      if (viewedProfile && viewedProfile?.recipes?.recipes.length > 0) {
        setShowPosts(false);
      } else {
        if (viewedProfile && (viewedProfile?.pinned?.pinned.length > 0 || viewedProfile?.posts?.posts.length > 0)) {
          setShowPosts(true);
        } else {
          //Default to empty recipes if user hasn't posted anything
          setShowPosts(false);
        }
      }
    }
  };

  const handleShowPosts = (showPosts) => {
    window.scrollTo(0, 0);
    setShowPosts(showPosts);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    asyncGetProfile();

    return () => {
      resetViewedProfile();
    };
  }, [isAuthenticated, currentProfile, username]);

  const profileInfo = (
    <>
      {viewedProfile ? (
        <div className="flex flex-col w-full p-4 md:p-8 md:border md:border-separator md:rounded-lg md:shadow-sm sticky h-fit mb-2 lg:static lg:w-full lg:max-w-[450px]">
          <div className="flex flex-row items-start justify-between mb-4">
            <div className="flex flex-row items-center gap-4 w-full">
              <Link to={`/${viewedProfile.username}`}>
                <img
                  src={
                    viewedProfile && viewedProfile.picture && viewedProfile.picture !== ""
                      ? viewedProfile.picture
                      : DefaultPFP
                  }
                  alt="Your profile"
                  className="h-[4rem] w-[4rem] object-cover rounded-[50%] "
                />
              </Link>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  {viewedProfile && viewedProfile.name}
                </Typography>
                <div className="flex flex-row items-center">
                  <Typography variant="subtitle1" className="text-mainGreen leading-tight font-medium">
                    {viewedProfile && viewedProfile.username && `@${viewedProfile.username}`}
                  </Typography>
                  {viewedProfile && viewedProfile.isPlus && (
                    <PlusIcon className="h-[1rem] w-[1rem] fill-mainGreen ml-1" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                disableRipple
                className="p-0 pb-1 min-w-0 hover:bg-transparent"
                onClick={() => setShowOptionsModal(true)}
              >
                <FontAwesomeIcon icon={faEllipsis} className="h-[1.25rem] text-primaryText ml-2" />
              </Button>
            </div>
          </div>

          <div className="w-full flex flex-row justify-between pb-4">
            <div className="text-center flex flex-col items-center justify-end">
              {viewedProfile && viewedProfile.viewedMine ? (
                <div className="flex flex-row items-end">
                  <Link to="/editprofile">
                    <FontAwesomeIcon icon={faUserPen} className="h-[1rem] text-secondaryText-600" />
                  </Link>
                  <Link to="/settings">
                    <FontAwesomeIcon icon={faGear} className="h-[1rem] text-secondaryText-600 ml-2" />
                  </Link>
                </div>
              ) : (
                <FollowButton
                  isFollowing={viewedProfile && viewedProfile.isFollowing}
                  userid={viewedProfile && viewedProfile.id}
                  isProfile={true}
                  onFollowCallback={onFollowCallback}
                  onUnfollowCallback={onUnfollowCallback}
                />
              )}
            </div>
            <div className="flex flex-row gap-4">
              <Button className="p-0 hover:bg-transparent" disableRipple onClick={() => handleShowFollowersModal()}>
                <div className="text-center flex flex-col items-center justify-end">
                  <Typography variant="caption" className="text-secondaryText">
                    Followers
                  </Typography>
                  <Typography variant="subtitle1" className="font-normal leading-none mt-1">
                    {commaFormatValue(followerCount)}
                  </Typography>
                </div>
              </Button>
              <Button className="p-0 hover:bg-transparent" disableRipple onClick={() => handleShowFollowingModal()}>
                <div className="text-center flex flex-col items-center justify-end">
                  <Typography variant="caption" className="text-secondaryText">
                    Following
                  </Typography>
                  <Typography variant="subtitle1" className="font-normal leading-none mt-1">
                    {commaFormatValue(followingCount)}
                  </Typography>
                </div>
              </Button>
            </div>
          </div>
          {viewedProfile && viewedProfile.bio && viewedProfile.bio !== "" && viewedProfile.bio !== null && (
            <div className="py-4 border-t border-separator">
              <Typography variant="body2" className="break-words">
                {viewedProfile && viewedProfile.bio}
              </Typography>
            </div>
          )}

          {viewedProfile && viewedProfile.link && (
            <div className="flex flex-row items-center">
              <LinkIcon className="text-secondaryText h-[0.625rem]" />
              <a href={viewedProfile.link} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="caption"
                  className="text-mainGreen ml-1 leading-none whitespace-nowrap overflow-ellipsis w-full pr-2 font-medium"
                >
                  {viewedProfile.link}
                </Typography>{" "}
              </a>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );

  return (
    <div>
      <div className={`${!isAuthenticated ? "min-h-[150vh] md:min-h-screen" : ""}  mt-[4.5rem]  md:mt-0`}>
        <Helmet>
          <title>{viewedProfile ? `@${viewedProfile.username} - thisPantry` : "thisPantry"}</title>
        </Helmet>

        <CustomModal
          open={showOptionsModal}
          handleClose={() => setShowOptionsModal(false)}
          contentStyleOverride={{ marginBottom: 0 }}
        >
          <ProfileOptionsModal handleClose={() => setShowOptionsModal(false)} />
        </CustomModal>
        <CustomModal
          open={showFollowersModal}
          handleClose={() => setShowFollowersModal(false)}
          contentStyleOverride={{ marginBottom: 0, minHeight: 0 }}
        >
          <ProfileFollowersModal
            userid={viewedProfile && viewedProfile.id}
            onFollowCallback={onFollowCallback}
            onUnfollowCallback={onUnfollowCallback}
            handleClose={() => setShowFollowersModal(false)}
          />
        </CustomModal>
        <CustomModal
          open={showFollowingModal}
          handleClose={() => setShowFollowingModal(false)}
          contentStyleOverride={{ marginBottom: 0, minHeight: 0 }}
        >
          <ProfileFollowingModal
            userid={viewedProfile && viewedProfile.id}
            onFollowCallback={onFollowCallback}
            onUnfollowCallback={onUnfollowCallback}
            handleClose={() => setShowFollowingModal(false)}
          />
        </CustomModal>
        {viewedLoading ? (
          <Spinner />
        ) : viewedProfile ? (
          <div
            className={`flex flex-col max-w-[768px] m-auto my-2 md:pl-[calc(5.5rem+0.5rem)] md:mb-[calc(4rem+0.5rem)] lg:flex-row lg:max-w-full lg:my-4 lg:mr-4 lg:gap-4
          `}
          >
            {profileInfo}
            <div className="flex flex-col flex-1 lg:m-0">
              <div className="flex flex-row w-full">
                <ProfileFeedManager showPosts={showPosts} setShowPosts={(value) => handleShowPosts(value)} />
              </div>
            </div>
          </div>
        ) : (
          <CannotFind />
        )}
      </div>
      {!isAuthenticated && <Footer />}
    </div>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getViewedProfile: PropTypes.func.isRequired,
  resetViewedProfile: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getViewedProfile,
  resetViewedProfile,
  showRegisterModal,
})(Profile);
