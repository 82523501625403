import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//API
import api from "../../api/api";

//UI
import { Card } from "../ui/card";

//Components
import Spinner from "../layout/Spinner";
import Post from "../feed/Post";

const StandalonePost = () => {
  const { postID, username } = useParams();

  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPost = async () => {
    try {
      //Get post data
      const res = await api.get(`/post/standalone/${postID}`);

      setPost({
        blockRepost: res.data.blockRepost,
        postData: res.data,
        postid: postID,
      });
      setError(false);
      setLoading(false);
      //If recipe redirect to recipe page
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);
  return (
    <div className="w-full py-[4rem] md:pl-[5rem] md:py-0">
      {loading ? (
        <Spinner />
      ) : error ? (
        <div>Error</div>
      ) : (
        <div className="max-w-[600px] m-auto mt-8">
          <Post post={post} />
        </div>
      )}
    </div>
  );
};

export default StandalonePost;
