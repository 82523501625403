import api from "../api/api";
import viper from "../api/viper";
import store, { history } from "../store";
import { setAlert } from "./alert";

// Redux types
import {
  RECIPE_CREATE_INGRED_ERROR,
  RECIPE_CREATE_UPDATE,
  RECIPE_CREATE_FORMAT_INGRED,
  GET_EDIT_RECIPE,
  GET_EXTERNAL_RECIPE,
  GET_GENERATED_RECIPE,
  RECIPE_CREATE_RESET,
} from "./types";

export const resetCreateRecipe = () => (dispatch) => {
  dispatch({
    type: RECIPE_CREATE_RESET,
  });
};

export const processIngredients = (ingredients, sectionIndex) => async (dispatch) => {
  try {
    if (ingredients.length === 0) {
      dispatch(setAlert("Ingredient cannot be empty", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_FORMAT_INGRED,
    });

    const res = await api.post("/ingredients/process", { ingredients });

    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    // Push the processed ingredients to the section
    if (res.data.ingredients.length > 0) {
      for (let i = 0; i < res.data.ingredients.length; i++) {
        let newIngredient = { ...res.data.ingredients[i] };

        if (!newIngredient.measured) {
          newIngredient.measured = !(
            newIngredient.quantity === 0 ||
            newIngredient.unit === "not_exact" ||
            newIngredient.unit === "to_taste"
          );
        }
        newSections[sectionIndex].ingredients.push(newIngredient);
      }
    } else {
      dispatch(setAlert("No ingredients detected", "warning"));
      return false;
    }

    dispatch({
      type: RECIPE_CREATE_UPDATE,
      payload: newSections,
    });
    return true;
  } catch (error) {
    const state = store.getState();
    const newSections = state.recipe.create.sections.map((section) => ({
      ...section,
      ingredients: [...section.ingredients],
    }));

    newSections[sectionIndex].ingredients.push({
      name: "Unknown Ingredient",
      quantity: "",
      unit: "",
      state: "",
      note: "",
      id: "",
      measured: true,
    });

    dispatch({
      type: RECIPE_CREATE_INGRED_ERROR,
      payload: newSections,
    });
    dispatch(setAlert("Error processing ingredient. Click the ingredient to add the info", "error"));
    return false;
  }
};

// Create section by title
export const createSection = (title) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  // Check for title uniqueness
  for (let section of newSections) {
    if (section.title === title) {
      dispatch(setAlert("Section title must be unique", "warning"));
      return false;
    }
  }

  newSections.push({
    title,
    ingredients: [],
    steps: [],
  });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });

  return true;
};

// Add ingredient to section
export const addIngredient = (sectionIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  newSections[sectionIndex].ingredients.push(ingredient);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Move ingredient in section to new index
export const moveIngredient = (sectionIndex, oldIndex, newIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  const [removed] = newSections[sectionIndex].ingredients.splice(oldIndex, 1);
  newSections[sectionIndex].ingredients.splice(newIndex, 0, removed);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove ingredient from section
export const removeIngredient = (sectionIndex, ingredientIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  newSections[sectionIndex].ingredients.splice(ingredientIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit ingredient in section
export const editIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
  }));

  let trimmedIngredient = { ...ingredient };
  // Trim strings for all keys in ingredient
  for (let key in trimmedIngredient) {
    if (typeof trimmedIngredient[key] === "string") {
      trimmedIngredient[key] = trimmedIngredient[key].trim();
    }
  }

  // Set alert if invalid fields
  if (ingredient.name === "") {
    dispatch(setAlert("Name cannot be empty", "warning"));
    return {
      error: "empty_name",
    };
  }

  newSections[sectionIndex].ingredients[ingredientIndex] = trimmedIngredient;
  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
  return {
    error: false,
  };
};

// Add step to section
export const addStep = (sectionIndex, stepText) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.push({ text: stepText, tips: [] });

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit step in section (including adding tip)
export const editSectionStep = (sectionIndex, stepIndex, data) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps[stepIndex] = {
    text: data.text,
    tips: data.tip && data.tip !== "" ? [data.tip] : [],
  };

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove step from section
export const removeStep = (sectionIndex, stepIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    steps: [...section.steps],
  }));

  newSections[sectionIndex].steps.splice(stepIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Edit section title
export const editSectionTitle = (sectionIndex, title) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    title: section.title === state.recipe.create.sections[sectionIndex].title ? title.trim() : section.title,
  }));

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Remove section
export const deleteSection = (sectionIndex) => (dispatch) => {
  const state = store.getState();
  const newSections = state.recipe.create.sections.map((section) => ({
    ...section,
    ingredients: [...section.ingredients],
    steps: [...section.steps],
  }));

  newSections.splice(sectionIndex, 1);

  dispatch({
    type: RECIPE_CREATE_UPDATE,
    payload: newSections,
  });
};

// Get recipe to edit, prep for editing
export const fetchRecipeToEdit = (recipeid) => async (dispatch) => {
  try {
    const res = await api.get(`/recipes/edit/${recipeid}`);

    dispatch({
      type: GET_EDIT_RECIPE,
      payload: res.data,
    });

    return {
      canEdit: true,
      recipe: res.data,
    };
  } catch (error) {
    return {
      canEdit: false,
    };
  }
};

//Search for recipes to insert as a section

//Get external recipe for section
export const fetchExternalRecipe = (url) => async (dispatch) => {
  try {
    /*
    const res = await viper.post(
      "/recipes/find",
      { url },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 || status === 404 ;
        },
      }
    );*/

    const res = await api.post(
      "/recipes/find",
      { url },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 || status === 404;
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Rate limit. Try again soon.",
        },
      };
    } else if (res.status === 404) {
      return {
        error: 404,
        msg: "Recipe not found",
      };
    }

    //Insert ingredients into each section
    let recipe = res.data.recipe;

    if (!recipe) {
      return {
        error: 404,
        msg: "Recipe not found.",
      };
    }

    for (let i = 0; i < recipe.sections.length; i++) {
      recipe.sections[i].ingredients = recipe.ingredients;
    }

    //Format course and cuisine for Searchable component
    if (recipe.course && typeof recipe.course === "string") {
      recipe.course = { value: recipe.course, name: recipe.course };
    }

    if (recipe.cuisine && typeof recipe.cuisine === "string") {
      recipe.cuisine = { value: recipe.cuisine, name: recipe.cuisine };
    }

    dispatch({
      type: GET_EXTERNAL_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};

export const fetchGeneratedRecipe = (query) => async (dispatch) => {
  try {
    /* Route through express server now, DNS fix
    const res = await viper.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429 ;
        },
      }
    );
    */

    const res = await api.post(
      "/recipes/generate",
      { text: query },
      {
        validateStatus: (status) => {
          return (status >= 200 && status < 300) || status === 429;
        },
      }
    );

    if (res.status === 429) {
      //Throw response error
      return {
        error: {
          code: 429,
          msg: "Rate limit. Try again soon.",
        },
      };
    }

    if (res.status === 402) {
      return {
        error: {
          code: 402,
          msg: "Plus is required for this feature.",
        },
      };
    }
    //Insert ingredients into each section
    let recipe = res.data.recipe;

    dispatch({
      type: GET_GENERATED_RECIPE,
      payload: recipe,
    });

    return recipe;
  } catch (error) {
    return {
      error: {
        code: 500,
        msg: "Server Error",
      },
    };
  }
};
