import React, { useState } from "react";
import { Typography, Button } from "@mui/material";

const testBreakfast = [
  {
    name: "Oatmeal with Berries",
    user: {
      username: "sofiawillam",
    },
    macros: {
      calories: 400,
    },
  },
];

const MealSection = ({ type }) => {
  return (
    <div className="w-full border-t border-separator">
      <div className="px-4 py-2">
        <Typography variant="subtitle1">{type}</Typography>
      </div>
      <div></div>
    </div>
  );
};

const MealItem = ({ name, user, macros, ingredients }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Typography>{name}</Typography>
      <Typography>@{user.username}</Typography>
      <div></div>
    </div>
  );
};

export const RecipeHistory = () => {
  return (
    <div className="flex flex-col border border-separator rounded-md shadow-sm">
      <div className="flex flex-row justify-between items-center px-4 py-3">
        <Typography variant="h6">My Meals</Typography>
        <Button>+ Add a Meal</Button>
      </div>

      <div className="flex flex-col">
        <MealSection type="Breakfast" />
        <MealSection type="Lunch" />
        <MealSection type="Dinner" />
        <MealSection type="Snacks" />
      </div>
    </div>
  );
};

export default RecipeHistory;
