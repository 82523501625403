import React from "react";
import { preventWidows } from "../../utils/text";

import PlusImage from "./res/info/card.jpg";
//MUI Components
import Typography from "@mui/material/Typography";
const CheckoutInfo = () => {
  return (
    <div className="w-full h-full">
      <div className="w-full h-full md:rounded-3xl relative overflow-hidden flex flex-col min-h-[400px] justify-end">
        <div
          className="absolute inset-0 bg-cover bg-center w-full h-full"
          style={{ backgroundImage: `url(${PlusImage})` }}
        ></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
        <div className="w-full flex flex-col justify-end px-4 py-8 md:px-8 md:py-12 relative z-10 h-full">
          <div>
            <Typography variant="h3" className="mb-2 text-white font-medium text-3xl md:text-4xl lg:text-5xl">
              {preventWidows("Be the first to experience the future")}
            </Typography>
            <Typography
              variant="subtitle1"
              className="leading-snug text-secondaryText-100 text-sm sm:text-base md:text-lg"
            >
              {preventWidows("Unlock early access to our premium beta test for $12/month and keep the price forever.")}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutInfo;
