import React from "react";
import { Link } from "react-router-dom";

//MUI
import { Typography, Button } from "@mui/material";

import { ReactComponent as PlusIcon } from "../../../assets/icons/svg/leaf-solid.svg";

const CommentHighlight = ({ loading, data }) => {
  return (
    <div className="ml-14 mr-2 cursor-pointer mb-4">
      <div className="flex flex-col items-start">
        <div className="flex flex-row items-center">
          <Typography variant="caption" className="text-primaryText font-medium leading-tight">{`@${
            data && data.username ? data.username : "loading"
          }`}</Typography>

          {data && data.isPlus && <PlusIcon className="h-[0.5rem] w-[0.5rem] fill-mainGreen ml-1" />}
        </div>

        <Typography variant="body2" className="break-all w-full break-words">
          {data && data.text}
        </Typography>
        <Button className="p-0 normal-case hover:bg-transparent" disableRipple>
          <Typography variant="caption" className="text-secondaryText font-normal mt-2">
            View all comments
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default CommentHighlight;
