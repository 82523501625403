import React, { useState } from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart, CartesianGrid, LabelList, Line, LineChart, XAxis } from "recharts";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChartContainer, ChartConfig, ChartTooltip, ChartTooltipContent } from "../ui/chart";

import { Typography, Button, IconButton } from "@mui/material";

//Icons
import { ReactComponent as ChevronLeft } from "../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chart-line.svg";
import { Flame } from "lucide-react";

//Charts
import RadialChart from "../charts/RadialChart";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import MultiAreaChart from "../charts/MultiAreaChart";

//Sections
import RecipeHistory from "./sections/RecipeHistory";
import Macronutrients from "./sections/Macronutrients";
import Expenses from "./sections/Expenses";

export const InsightsPage = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  //Todays macro targets at top
  //Planned meals
  //Daily macro history
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
  };

  const formatDay = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem]">
      <div className="w-full flex flex-col p-4 gap-4">
        <div className="flex flex-row justify-between border border-separator rounded-md px-4 py-3 shadow-sm">
          <div>
            <Typography variant="subtitle1" className="text-secondaryText leading-none">
              {formatDay(currentDate)}
            </Typography>
            <Typography variant="h5">{formatDate(currentDate)}</Typography>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Typography variant="caption" className="text-red-700">
              10 day streak
            </Typography>
            <Flame className="h-4 text-red-700" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-1/2">
            <Macronutrients />
          </div>
          <div className="w-full md:w-1/2">
            <Expenses />
          </div>
        </div>
        <RecipeHistory />
      </div>
    </div>
  );
};

InsightsPage.propTypes = {
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(InsightsPage);
