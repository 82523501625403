import React, { useState, useEffect, useRef } from "react";
import { Typography, Button } from "@mui/material";

const MaxLinesTextBlock = ({
  text = "",
  maxLines = 1,
  variant = "body1",
  buttonVariant = "body2",
  rootStyles = "",
  textStyles = "font-normal",
  buttonTextStyles = "font-normal",
  buttonStyles = "",
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [displayedText, setDisplayedText] = useState(text);
  const textRef = useRef(null);
  const containerRef = useRef(null);

  const calculateTruncation = () => {
    if (textRef.current && containerRef.current) {
      const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight);
      const maxHeight = lineHeight * maxLines;
      let tempText = text;
      textRef.current.innerText = tempText;

      if (textRef.current.scrollHeight <= maxHeight) {
        setIsTruncated(false);
        setDisplayedText(text);
        return;
      }

      setIsTruncated(true);
      let truncatedText = "";

      for (let i = 0; i < text.length; i++) {
        truncatedText = text.slice(0, i) + "...";
        textRef.current.innerText = truncatedText;

        if (textRef.current.scrollHeight > maxHeight) {
          truncatedText = text.slice(0, i - 1) + "...";
          break;
        }
      }

      setDisplayedText(truncatedText);
    }
  };

  useEffect(() => {
    calculateTruncation();
    window.addEventListener("resize", calculateTruncation);

    return () => {
      window.removeEventListener("resize", calculateTruncation);
    };
  }, [text, maxLines]);

  return (
    <div className="w-full">
      <div
        ref={containerRef}
        className={`overflow-hidden whitespace-pre-wrap ${showMore ? "" : `line-clamp-${maxLines}`} ${rootStyles}`}
        style={showMore ? {} : { display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: maxLines }}
      >
        <Typography ref={textRef} variant={variant} className={textStyles}>
          {showMore ? text : displayedText}
        </Typography>
      </div>
      {isTruncated && (
        <Button
          onClick={() => setShowMore(!showMore)}
          disableRipple
          className={`px-0 text-secondaryText hover:bg-transparent ${buttonStyles}`}
        >
          <Typography variant={buttonVariant} className={buttonTextStyles}>
            {showMore ? "Show Less" : "Show More"}
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default MaxLinesTextBlock;
