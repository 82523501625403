//Alert types
import { TOGGLE_PLUS_UPGRADE_MODAL, USER_LOADED } from "../actions/types";

const initialState = {
  isPlus: null,
  modals: {
    upgrade: false,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_PLUS_UPGRADE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          upgrade: payload,
        },
      };
    case USER_LOADED:
      return {
        ...state,
        isPlus: payload.isPlus ? payload.isPlus : false,
      };
    default:
      return state;
  }
}
