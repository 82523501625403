import React from "react";
import { Link } from "react-router-dom";
import { Divider, Typography, Button } from "@mui/material";

export const EndOfRecipes = ({ loading }) => {
  const onNavigation = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!loading && (
        <div className="my-8 py-8 px-4 md:px-8 w-full flex flex-col items-start border-t border-separator">
          <div>
            <Typography variant="h6" className="font-normal primaryText">
              Can't find the recipe you're looking for?
            </Typography>
            <Typography variant="h6" className="font-normal primaryText mb-8">
              Be the first to add it!
            </Typography>
          </div>

          <div className="flex flex-col gap-2 items-center w-full md:w-auto lg:min-w-[500px]">
            <Link to="/create/recipe" onClick={() => onNavigation()} className="w-full">
              <Button className="p-0 bg-background" fullWidth>
                <div className="px-4 py-3 border border-separator rounded-md w-full">
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle1" className="font-normal leading-tight ">
                      Create a Recipe
                    </Typography>
                  </div>
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                      Share your kitchen's best kept secret
                    </Typography>
                  </div>
                </div>
              </Button>
            </Link>
            <Link to="/create/recipe?type=import" onClick={() => onNavigation()} className="w-full">
              <Button className="p-0 bg-background" fullWidth>
                <div className="px-4 py-3 border  border-separator rounded-md w-full">
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle1" className="font-normal leading-tight ">
                      Import a Recipe
                    </Typography>
                  </div>
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                      Quickly add a recipe to your collection
                    </Typography>
                  </div>
                </div>
              </Button>
            </Link>
            <Link to="/create/recipe?type=generate" onClick={() => onNavigation()} className="w-full">
              <Button className="p-0 bg-background" fullWidth>
                <div className="px-4 py-3 border  border-separator rounded-md w-full">
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle1" className="font-normal leading-tight ">
                      Generate a Recipe
                    </Typography>
                  </div>
                  <div className="flex items-start text-start">
                    <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                      with thisPantry<span className="text-mainGreen font-semibold">+</span>
                    </Typography>
                  </div>
                </div>
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default EndOfRecipes;
