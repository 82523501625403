import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Typography, IconButton } from "@mui/material";
import MaxLinesTextBlock from "../../layout/maxlinestextblock/MaxLinesTextBlock";

//Icons
import { ReactComponent as HeartIcon } from "../../../assets/icons/svg/heart.svg";
import { ReactComponent as FilledHeartIcon } from "../../../assets/icons/svg/heart-solid.svg";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/svg/ellipsis.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/svg/leaf-solid.svg";

//Defaults
import ProfileImg from "../../../assets/default/user.jpg";

//Modals
import CustomModal from "../../modal/CustomModal";
import PostCommentOptionsModal from "./PostCommentOptionsModal";

//Utility Functions
import { shortInteractionCount } from "../../../utils/post";
function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + "y";
  }
  interval = Math.floor(seconds / 604800);
  if (interval > 1) {
    return interval + "w";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 0) {
    // Modified this line to fix the issue
    return interval + "d";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + "h";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + "m";
  }
  return "Just now";
}

/*
function formatLikesCount(count) {
  if (count < 1000) {
    return count.toString() + " likes";
  } else if (count < 1000000) {
    return (Math.floor(count / 100) / 10).toFixed(1) + "k likes";
  } else {
    return (Math.floor(count / 100000) / 10).toFixed(1) + "m likes";
  }
}*/

//To Do:

//Post options modal
//Like comment
//Format long posts -> Collapsable?

//Replies:
//Reply functionality
//Show 1 relevant reply by default -> Priority -> friend, OP, most liked, most recent
//Like reply
//View all replies (expand)
const PostComment = ({ comment, postid, auth: { isAuthenticated } }) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  return (
    <div className="px-4 pb-8 w-full whitespace-pre-wrap break-words">
      <CustomModal
        open={showOptionsModal}
        handleClose={() => setShowOptionsModal(false)}
        contentStyleOverride={{ minHeight: 0, marginBottom: 0 }}
      >
        <PostCommentOptionsModal comment={comment} postid={postid} handleClose={() => setShowOptionsModal(false)} />
      </CustomModal>
      <div className="flex flex-row justify-between items-center">
        <Link to={`/${comment.user.username}`}>
          <div className="flex flex-row items-center">
            <img
              src={
                comment && comment.user && comment.user.picture && comment.user.picture !== ""
                  ? comment.user.picture
                  : ProfileImg
              }
              alt={"My Profile"}
              className="rounded-full border border-secondaryTextOnWhite h-8 w-8 object-cover mr-2"
            ></img>
            <Typography
              variant="subtitle2"
              className="leading-none font-normal"
            >{`@${comment.user.username}`}</Typography>
            {comment.user.isPlus && <PlusIcon className="h-[0.75rem] w-[0.75rem] fill-mainGreen ml-1" />}
            <Typography variant="body2" className="text-secondaryText font-light ml-2 leading-none">
              {timeSince(new Date(comment.date))}
            </Typography>
          </div>
        </Link>
        <div>
          {isAuthenticated && (
            <IconButton
              className="hover:bg-transparent pr-0"
              onClick={() => setShowOptionsModal(true)}
              disableRipple
              size="large"
            >
              <EllipsisIcon className="w-3 fill-secondaryText hover:fill-primaryText hover:bg-transparent" />
            </IconButton>
          )}
        </div>
      </div>

      <div className="mb-2 mt-1">
        <MaxLinesTextBlock text={comment.text} maxLines={3} buttonStyles="pt-1" />
      </div>
      <div className="flex flex-row items-center">
        <IconButton className="p-0 mr-2" size="large">
          <HeartIcon className="h-4 fill-secondaryText" />
        </IconButton>

        <Typography variant="caption" className="font-normal text-secondaryText">
          {shortInteractionCount(comment.likes.length)}
        </Typography>
      </div>
    </div>
  );
};

PostComment.propTypes = {
  comment: PropTypes.object.isRequired,
  postid: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PostComment);
