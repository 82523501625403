import React, { useState, Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Divider,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";

//Components
import RecipeSectionManager from "./components/recipe/RecipeSectionManager";
import ImageDropzone from "./components/images/ImageDropzone";
import ImageReorderPreview from "./components/images/ImageReorderPreview";
import RecipeSectionTags from "./components/recipe/RecipeSectionTags";
import Searchable from "../layout/searchable/Searchable";

//Icons
import { ReactComponent as ImagesIcon } from "../../assets/icons/svg/images.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/svg/question-circle.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chef.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCropSimple, faPlus } from "@fortawesome/free-solid-svg-icons";

//Actions
import { processIngredients, fetchRecipeToEdit, resetCreateRecipe } from "../../actions/createrecipe";
import { setAlert } from "../../actions/alert";
import { imageUploadReset } from "../../actions/media";
import { createRecipe } from "../../actions/recipe";
import { togglePlusUpgradeModal } from "../../actions/plus";

//Modals
import CustomModal from "../modal/CustomModal";
import AddRecipeSectionModal from "./components/recipe/AddRecipeSectionModal";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import PlusModal from "../plus/PlusModal";

//TO DO: Make sure if ounce unit and first time for ingredient that the ounce type is logged\

//TO DO:
//Horizontal scrollable carousel for uploaded images with delete button on top right, click to edit crop?

const CreateRecipe = ({
  processIngredients,
  recipe: { create, edit },
  user,
  setAlert,
  imageUploadReset,
  createRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  media: { images },
  plus,
  togglePlusUpgradeModal,
}) => {
  //Limits

  const { recipeid } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startType = queryParams.get("type");
  const startPrompt = queryParams.get("prompt");
  const [initialAddType, setInitialAddType] = useState("add");
  const [initialAddPrompt, setInitialAddPrompt] = useState("");

  const handleAddSectionModalClose = () => {
    setInitialAddType("add");
    setShowAddSection(false);
  };

  const [formView, setFormView] = useState("info");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [tags, setTags] = useState([]);
  const [isPublic, setIsPublic] = useState(true);

  const [servings, setServings] = useState("");

  const [recipeSource, setRecipeSource] = useState("");
  const [recipeSourceType, setRecipeSourceType] = useState("Add a source");
  const [recipeSourceURL, setRecipeSourceURL] = useState("");
  const [recipeSourceRefs, setRecipeSourceRefs] = useState(null);

  const onRecipeSourceChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSource(e.target.value);
    }
  };

  const onRecipeSourceURLChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSourceURL(e.target.value);
    }
  };

  const onServingsChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setServings(value);
    } else {
      if (isNaN(value)) {
        setAlert("Servings must be a number", "warning");
      } else if (value < 0) {
        setAlert("Servings must be greater than or equal to 0", "warning");
      } else if (value > 100) {
        setAlert("Servings must be less than or equal to 100", "warning");
      }
    }
  };

  const [showYield, setShowYield] = useState(false);
  const [showAdditionalTiming, setShowAdditionalTiming] = useState(false);

  const [recipeYield, setRecipeYield] = useState({
    amount: "",
    unit: "",
    ingredient: "",
  });

  const onYieldAmountChange = (e) => {
    const value = e.target.value;

    // Regular expression to check if the value is a valid fraction or ends with a slash
    const fractionRegex = /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$/;

    if (value === "" || fractionRegex.test(value)) {
      setRecipeYield({ ...recipeYield, amount: value });
      return;
    }

    if (value === "" || fractionRegex.test(value)) {
      setRecipeYield({ ...recipeYield, amount: value });
      return;
    }
    if (!isNaN(value) && value >= 1 && value <= 10000) {
      setRecipeYield({ ...recipeYield, amount: value });
    } else {
      if (isNaN(value)) {
        setAlert("Amount must be a number", "warning");
      } else if (value < 1) {
        setAlert("Amount must be greater than or equal to 1", "warning");
      } else if (value > 10000) {
        setAlert("Amount must be less than or equal to 10,000", "warning");
      }
    }
  };

  const onYieldUnitChange = (newValue) => {
    if (newValue === null) {
      setRecipeYield({ ...recipeYield, unit: "" });
      return;
    }
    if (newValue.value.length > 32) {
      setAlert("Yield Unit name must be less than 32 characters", "warning");
      return;
    }

    if (newValue.value.length === 0) {
      setRecipeYield({ ...recipeYield, unit: "" });
      return;
    }

    if (newValue.value.length < 3 && newValue.value.length > 0) {
      setAlert("Yield Unit name must be at least 3 characters", "warning");
      return;
    }

    setRecipeYield({ ...recipeYield, unit: newValue });
  };

  const onYieldIngredientChange = (newValue) => {
    console.log(newValue);
    if (newValue === null) {
      setRecipeYield({ ...recipeYield, ingredient: "" });
      return;
    }
    if (newValue.value.length > 128) {
      setAlert("Yield Ingredient name must be less than 128 characters", "warning");
      return;
    }

    if (newValue.value.length === 0) {
      setRecipeYield({ ...recipeYield, ingredient: "" });
      return;
    }

    if (newValue.value.length < 3 && newValue.value.length > 0) {
      setAlert("Yield Ingredient name must be at least 3 characters", "warning");
      return;
    }

    setRecipeYield({ ...recipeYield, ingredient: newValue.value });
  };

  const handleRemoveYield = () => {
    setRecipeYield({
      amount: "",
      unit: "",
      ingredient: "",
    });
    setShowYield(false);
  };

  const [timing, setTiming] = useState({
    prep: {
      hours: "",
      minutes: "",
    },
    cook: {
      hours: "",
      minutes: "",
    },
    total: {
      hours: "",
      minutes: "",
    },
  });

  const handleTimingChange = (type, field, value) => {
    setTiming((prevTime) => {
      let newHours = prevTime[type].hours === "" ? 0 : parseInt(prevTime[type].hours);
      let newMinutes = prevTime[type].minutes === "" ? 0 : parseInt(prevTime[type].minutes);

      if (field === "hours") {
        newHours = value === "" ? 0 : parseInt(value);
      } else if (field === "minutes") {
        newMinutes = value === "" ? 0 : parseInt(value);
      }

      return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
    });
  };

  const handleTimingBlur = (type) => {
    setTiming((prevTime) => {
      let newHours = prevTime[type].hours === "" ? 0 : parseInt(prevTime[type].hours);
      let newMinutes = prevTime[type].minutes === "" ? 0 : parseInt(prevTime[type].minutes);

      if (newMinutes > 59) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes = newMinutes % 60;
      }

      return { ...prevTime, [type]: { hours: newHours, minutes: newMinutes } };
    });
  };

  const handleRemoveAdditionalTiming = () => {
    setTiming({
      total: timing.total,
      cook: {
        hours: "",
        minutes: "",
      },
      prep: {
        hours: "",
        minutes: "",
      },
    });
    setShowAdditionalTiming(false);
  };

  //Nutrition State
  const [showAddNutrition, setShowAddNutrition] = useState(false);
  const [nutrition, setNutrition] = useState({
    calories: "",
    fat: "",
    saturatedFat: "",
    transFat: "",
    unsaturatedFat: "",
    cholesterol: "",
    sodium: "",
    carbohydrates: "",
    fiber: "",
    protein: "",
    sugars: "",
    naturalSugars: "",
  });

  const handleNutritionChange = (key, value) => {
    //Verify value is a number
    if (isNaN(value)) {
      setAlert("Please enter a valid number", "warning");
      return;
    }

    if (value < 0) {
      value = "";

      setAlert("Please enter a positive number", "warning");
      return;
    }

    if (value > 10000) {
      setAlert("Please enter a number less than 10,000", "warning");
      return;
    }
    setNutrition({ ...nutrition, [key]: value });
  };

  const handleRemoveMacros = () => {
    setNutrition({
      calories: "",
      fat: "",
      saturatedFat: "",
      transFat: "",
      unsaturatedFat: "",
      cholesterol: "",
      sodium: "",
      carbohydrates: "",
      fiber: "",
      protein: "",
      sugars: "",
      naturalSugars: "",
    });
    setShowAddNutrition(false);
  };

  const [boost, setBoost] = useState(true);

  const handleBoostChange = (value) => {
    if (value) {
      setBoost(true);
      setGenerateNutrition(true);
      setGenerateTips(true);
      setGenerateTags(true);
      setGenerateTiming(true);
    } else {
      setBoost(false);
      setGenerateNutrition(false);
      setGenerateTips(false);
      setGenerateTags(false);
      setGenerateTiming(false);
      setViewAIAreas(false);
    }
  };
  const [generateNutrition, setGenerateNutrition] = useState(true);
  const [generateTips, setGenerateTips] = useState(true);
  const [generateTags, setGenerateTags] = useState(true);
  const [generateTiming, setGenerateTiming] = useState(true);

  //AI State
  const [viewAIAreas, setViewAIAreas] = useState(false);

  //Cuisine State
  const [cuisine, setCuisine] = useState([]);
  const onCuisineChange = (items) => {
    setCuisine(items);
  };

  //Course state
  const [course, setCourse] = useState([]);
  const onCourseChange = (items) => {
    setCourse(items);
  };

  //Allergen state
  //TO DO: Detect from ingredients in sections
  const [allergens, setAllergens] = useState([]);
  const [allergenMayContain, setAllergenMayContain] = useState([]);

  const [showAllergens, setShowAllergens] = useState(false);
  const onAllergenChange = (items) => {
    setAllergens(items);
  };

  const onAllergenMayContainChange = (items) => {
    setAllergenMayContain(items);
  };

  const handleRemoveAllergens = () => {
    //Reset allergen state
    setAllergens([]);
    setAllergenMayContain([]);
    setShowAllergens(false);
  };

  //Friendly Diet State
  //TO DO: Detect from ingredients in sections
  const [friendlyDiets, setFriendlyDiets] = useState([]);
  const [showFriendlyDiets, setShowFriendlyDiets] = useState(false);
  const onFriendlyDietChange = (items) => {
    setFriendlyDiets(items);
  };

  const handleRemoveFriendlyDiets = () => {
    setFriendlyDiets([]);
    setShowFriendlyDiets(false);
  };

  //Modal Handling
  const [showAddSection, setShowAddSection] = useState(false);

  const handleNameChange = (e) => {
    const name = e.target.value;

    if (name.length < 100) {
      setName(name);
    } else {
      setAlert("Name must be less than 100 characters", "warning");
    }
  };

  const handleDescriptionChange = (e) => {
    const desc = e.target.value;

    if (desc.length < 2048) {
      setDescription(desc);
    } else {
      setAlert("Description must be less than 2048 characters", "warning");
    }
  };

  const handleAddSections = () => {
    if (create.sections.length < 10) {
      setInitialAddType("add");
      setShowAddSection(true);
    } else {
      setAlert("A recipe cannot have more than 10 sections", "warning");
    }
  };

  const handleFormSubmit = async (event) => {
    //TO DO: Add edit handling
    event.preventDefault();

    //Create recipe object
    let recipe = {
      name,
      description,
      tags,
      isPublic,
      nutrition,
      sections: create.sections,
      cuisine,
      course,
      allergens: showAllergens
        ? {
            contains: allergens,
            mayContain: allergenMayContain,
          }
        : false,
      friendlyDiets: showFriendlyDiets ? friendlyDiets : false,
      servings,
      yield: showYield ? recipeYield : false,
      timing,
      boost: boost
        ? {
            generateNutrition,
            generateTips,
            generateTags,
            generateTiming,
          }
        : false,
      source:
        recipeSourceType !== "Add a source"
          ? {
              type: recipeSourceType,
              url: recipeSourceURL,
              text: recipeSource.trim(),
              ref: recipeSourceRefs,
            }
          : null,
    };

    //===Data Verification===
    //Name

    let isError = false;
    if (name.length < 3) {
      setAlert("Name must be at least 3 characters", "warning");
      isError = true;
    }

    if (name.length > 128) {
      setAlert("Name must be less than 128 characters", "warning");
      isError = true;
    }

    //Description
    if (!description) {
      recipe.description = "";
    } else {
      recipe.description = description;
    }

    recipe.description = recipe.description.trim();
    if (recipe.description.length > 2048) {
      setAlert("Description must be less than 2048 characters", "warning");
      isError = true;
    }

    //Tags
    if (tags.length < 3) {
      setAlert("Add at least 3 tags", "warning");
      isError = true;
    }

    if (tags.length > 20) {
      setAlert("Maximum of 20 tags allowed", "warning");
      isError = true;
    }

    //Recipe source
    if (recipe.source) {
      if (recipe.source.url.length > 1024) {
        setAlert("Source link must be less than 1024 characters in length");
      }

      if (recipe.source.text.length > 128) {
        setAlert("Source title must be less than 128 characters in length");
      }
    }

    let tagLengthLimit = false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].length > 30) {
        tagLengthLimit = true;
        break;
      }
    }

    if (tagLengthLimit) {
      setAlert("Tags must be less than 30 characters", "warning");
      isError = true;
    }

    if (recipe.source) {
      if (recipe.source.length > 256) {
        setAlert("Recipe source must be less than 256 characters", "warning");
        isError = true;
      }
    }

    //Timing
    if (timing.total.hours === "" && timing.total.minutes === "") {
      setAlert("Total time is required", "warning");
      isError = true;
    }

    if (!showAdditionalTiming) {
      delete recipe.timing.prep;
      delete recipe.timing.cook;
    }

    //Output
    //Servings
    if (servings === "") {
      setAlert("Number of servings is required", "warning");
      isError = true;
    }

    const servingsNumber = parseInt(servings);
    if (isNaN(servingsNumber) || servingsNumber < 1 || servingsNumber > 100) {
      setAlert("Number of servings must be a number between 1 and 100", "warning");
      isError = true;
    }

    //Yield
    if (recipe.yield) {
      //Must have either amount and unit or amount and ingredient
      //Amount
      if (!recipe.yield.amount || recipe.yield.amount === "") {
        setAlert("Yield amount is required", "warning");
        isError = true;
      }

      // Check if the amount is a valid fraction
      const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
      if (fractionRegex.test(recipe.yield.amount)) {
        const [numerator, denominator] = recipe.yield.amount.split("/");
        if (!isNaN(numerator) && !isNaN(denominator)) {
          const decimalValue = parseFloat(numerator) / parseFloat(denominator);
          if (decimalValue <= 0) {
            setAlert("Yield amount must be greater than 0", "warning");
            isError = true;
          } else {
            recipe.yield.amount = decimalValue.toFixed(3);
          }
        }
      } else if (isNaN(recipe.yield.amount) || recipe.yield.amount <= 0) {
        setAlert("Yield amount must be a number or a fraction greater than 0", "warning");
        isError = true;
      }

      //Map searchable objects to just values for ingredient and unit
      recipe.yield.unit && recipe.yield.unit.value
        ? (recipe.yield.unit = recipe.yield.unit.value)
        : (recipe.yield.unit = "");
      recipe.yield.ingredient && recipe.yield.ingredient.value
        ? (recipe.yield.ingredient = recipe.yield.ingredient.value)
        : (recipe.yield.ingredient = "");

      let hasUnitOrIngredient = false;

      //Unit

      if (recipe.yield.unit && recipe.yield.unit !== "") {
        hasUnitOrIngredient = true;
        if (recipe.yield.unit.length > 32) {
          setAlert("Yield unit must be less than 32 characters", "warning");
          isError = true;
        }
      }

      //Ingredient
      console.log(recipe.yield);
      if (recipe.yield.ingredient && recipe.yield.ingredient !== "") {
        hasUnitOrIngredient = true;
        if (recipe.yield.ingredient.length > 64) {
          setAlert("Yield ingredient must be less than 64 characters", "warning");
          isError = true;
        }
      }

      if (!hasUnitOrIngredient) {
        setAlert("Yield must have a unit or ingredient", "warning");
        isError = true;
      }
    }

    //Course
    //Strip values from searchables
    recipe.course && recipe.course.value ? (recipe.course = recipe.course.value) : (recipe.course = "");

    if (recipe.course && recipe.course.length > 32) {
      setAlert("Course must be less than 32 characters", "warning");
      isError = true;
    }

    //Cuisine
    //Strip values from searchables
    recipe.cuisine && recipe.cuisine.value ? (recipe.cuisine = recipe.cuisine.value) : (recipe.cuisine = "");

    if (recipe.cuisine && recipe.cuisine.length > 32) {
      setAlert("Cuisine must be less than 32 characters", "warning");
      isError = true;
    }

    //Nutrition
    //Macronutrients
    for (const [key, value] of Object.entries(recipe.nutrition)) {
      if (isNaN(value) || value < 0 || value > 10000) {
        setAlert(`Macronutrients must be a number between 0 and 10,000`, "warning");
        isError = true;
      }
    }

    //Allergens
    //Strip values from searchables
    if (recipe.allergens) {
      recipe.allergens.mayContain = recipe.allergens.mayContain.map((item) => item.value);
      recipe.allergens.contains = recipe.allergens.contains.map((item) => item.value);

      if (recipe.allergens.length > 20) {
        setAlert("Maximum of 20 allergens allowed", "warning");
        isError = true;
      }

      if (recipe.allergens.mayContain.length > 20) {
        setAlert("Maximum of 20 potential allergens allowed", "warning");
        isError = true;
      }

      for (let i = 0; i < recipe.allergens.contains.length; i++) {
        if (recipe.allergens.contains[i].length > 32) {
          setAlert("Allergen must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.allergens.contains[i].length < 3) {
          setAlert("Allergen must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }

      for (let i = 0; i < recipe.allergens.mayContain.length; i++) {
        if (recipe.allergens.mayContain[i].length > 32) {
          setAlert("Potential allergen must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.allergens.mayContain[i].length < 3) {
          setAlert("Potential allergen must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }
    }

    //Friendly Diets
    //Strip values from searchables
    if (recipe.friendlyDiets) {
      recipe.friendlyDiets = recipe.friendlyDiets.map((item) => item.value);

      for (let i = 0; i < recipe.friendlyDiets.length; i++) {
        if (recipe.friendlyDiets[i].length > 32) {
          setAlert("Friendly Diet must be less than 32 characters", "warning");
          isError = true;
          break;
        }

        if (recipe.friendlyDiets[i].length < 3) {
          setAlert("Friendly Diet must be at least 3 characters", "warning");
          isError = true;
          break;
        }
      }
    }

    //Loop over sections, verify each section has a title and *some* content
    let hasIngredients = false;
    let hasInstructions = false;

    if (recipe.sections.length === 0) {
      setAlert("Recipes must have at least one section", "warning");
      isError = true;
    }

    if (recipe.sections.length > 10) {
      setAlert("A recipe cannot have more than 10 sections", "warning");
      isError = true;
    }

    for (let i = 0; i < recipe.sections.length; i++) {
      let section = recipe.sections[i];

      if (section.title.length === 0) {
        setAlert("Sections must have a title", "warning");
        isError = true;
      }

      if (section.title.length > 50) {
        setAlert("Section title must be less than 50 characters", "warning");
        isError = true;
      }

      if (section.ingredients && section.ingredients.length > 0) {
        hasIngredients = true;
        if (section.ingredients.length > 100) {
          setAlert("A section cannot have more than 100 ingredients", "warning");
          isError = true;
        } else {
          for (let ing = 0; ing < section.ingredients.length; ing++) {
            let sectionIngredient = section.ingredients[ing];

            //Ingredient name
            if (sectionIngredient.name.length === 0) {
              setAlert("Ingredients must have a name", "warning");
              isError = true;
            }

            if (sectionIngredient.name.length < 3) {
              setAlert("Ingredient name must be at least 3 characters", "warning");
              isError = true;
            }

            if (sectionIngredient.name.length > 64) {
              setAlert("Ingredient name must be less than 64 characters", "warning");
              isError = true;
            }

            //Ingredient quantity
            // Check if the amount is a valid fraction
            if (sectionIngredient.quantity !== "") {
              if (sectionIngredient.measured) {
                const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
                if (fractionRegex.test(sectionIngredient.quantity)) {
                  const [numerator, denominator] = sectionIngredient.quantity.split("/");
                  if (!isNaN(numerator) && !isNaN(denominator)) {
                    const decimalValue = parseFloat(numerator) / parseFloat(denominator);
                    if (decimalValue <= 0 && sectionIngredient.quantity !== "") {
                      setAlert("Ingredient amount must be greater than 0", "warning");
                      isError = true;
                    } else {
                      sectionIngredient.quantity = decimalValue.toFixed(3);
                    }
                  }
                } else if (isNaN(sectionIngredient.quantity) || sectionIngredient.quantity <= 0) {
                  setAlert("Ingredient amount must be a number or a fraction greater than 0", "warning");
                  isError = true;
                }
              }
            }

            //Ingredient unit
            if (sectionIngredient.unit) {
              if (sectionIngredient.unit.length > 32) {
                setAlert("Ingredient unit must be less than 32 characters", "warning");
                isError = true;
              }
            }

            //Ingredient state
            if (sectionIngredient.state) {
              if (sectionIngredient.state.length > 32) {
                setAlert("Ingredient state must be less than 32 characters", "warning");
                isError = true;
              }
            }

            //Note
            if (sectionIngredient.note) {
              if (sectionIngredient.note.length > 128) {
                setAlert("Ingredient note must be less than 128 characters", "warning");
                isError = true;
              }
            }
          }
        }
      }

      if (section.steps && section.steps.length > 0) {
        hasInstructions = true;

        if (section.steps.length > 100) {
          setAlert("A section cannot have more than 100 steps", "warning");
          isError = true;
        } else {
          for (let s = 0; s < section.steps.length; s++) {
            let step = section.steps[s];

            //Step text
            if (step.text.length === 0) {
              setAlert("Steps must have text", "warning");
              isError = true;
            }

            if (step.text.length > 512) {
              setAlert("A step's text must be less than 512 characters", "warning");
              isError = true;
            }
            //Step tip
            if (step.tips && step.tips.length > 0) {
              if (step.tips.length > 1) {
                setAlert("A step can only have one tip", "warning");
                isError = true;
              } else {
                if (step.tips[0].length > 256) {
                  setAlert("A step's tip must be less than 256 characters", "warning");
                  isError = true;
                }
              }
            }
          }
        }
      }
    }

    if (!hasIngredients || !hasInstructions) {
      setAlert("Recipes must have at least one ingredient and step", "warning");
      isError = true;
    }

    if (isError) {
      return;
    }

    try {
      const create = await createRecipe(recipe);

      if (create.url) {
        setAlert("Recipe created", "success");
        navigate(`/${user.username}/${create.url}`);
      }
    } catch {
      setAlert("Error creating recipe", "error");
    }
  };

  const prefillRecipeFields = (recipe) => {
    if (recipe.name && recipe.name !== "") {
      if (name === "") {
        setName(recipe.name);
      }
    }

    if (recipe.description && recipe.description !== "") {
      if (description === "") {
        setDescription(recipe.description);
      }
    }

    if (recipe.cuisine) {
      if (cuisine && cuisine.length === 0) {
        setCuisine(recipe.cuisine);
      }
    }

    if (recipe.course) {
      if (course && course.length === 0) {
        setCourse(recipe.course);
      }
    }

    if (recipe.tags) {
      if (tags.length + recipe.tags.length < 20) {
        setTags([...tags, ...recipe.tags]);
      }
    }

    if (recipe.servings) {
      setServings(recipe.servings);
    }

    if (recipe.source) {
      setRecipeSourceType(recipe.source.type);
      setRecipeSourceURL(recipe.source.url ? recipe.source.url : "");
      setRecipeSource(recipe.source.text ? recipe.source.text : "");
      setRecipeSourceRefs(recipe.source.ref ? recipe.source.ref : null);
    }

    if (recipe.timing) {
      //Check objects arent empty or zeroes
      let timingObj = {
        prep: {
          hours: "",
          minutes: "",
        },
        cook: {
          hours: "",
          minutes: "",
        },
        total: {
          hours: "",
          minutes: "",
        },
      };

      if (recipe.timing.total && Object.keys(recipe.timing.total).length !== 0) {
        timingObj.total = recipe.timing.total;
      }

      if (recipe.timing.prep && Object.keys(recipe.timing.prep).length !== 0) {
        timingObj.prep = recipe.timing.prep;
        setShowAdditionalTiming(true);
      }

      if (recipe.timing.cook && Object.keys(recipe.timing.cook).length !== 0) {
        timingObj.cook = recipe.timing.cook;
        setShowAdditionalTiming(true);
      }

      setTiming(timingObj);
    }

    if (recipe.nutrition) {
      //If macros not all null, show macros
      //If allergens not empty, show
      if (recipe.nutrition?.allergens?.contains.length > 0 || recipe.nutrition?.allergens?.mayContain.length > 0) {
        setAllergens(recipe.nutrition.allergens.contains);
        setShowAllergens(true);
      }
      //If diets not empty, show

      if (recipe.nutrition) {
        if (recipe.nutrition.friendlyDiets) {
          if (recipe.nutrition.friendlyDiets.length > 0) {
            setFriendlyDiets(recipe.nutrition.friendlyDiets);
            setShowFriendlyDiets(true);
          }
        }
      }

      if (recipe.nutrition?.macros) {
        let returnMacros = nutrition;

        //Format macros
        for (const [macro, value] of Object.entries(recipe.nutrition.macros)) {
          if (typeof value === "object") {
            returnMacros[macro] = value.value;
          } else {
            returnMacros[macro] = value;
          }
        }

        setNutrition(returnMacros);
        //Show macros if we have values
        if (Object.values(recipe.nutrition.macros).some((value) => value !== "")) {
          setShowAddNutrition(true);
        }
      }
    }

    console.log(recipe);

    if (recipe.yield) {
      console.log(recipe.yield);
      setShowYield(true);

      setRecipeYield({
        ...recipe.yield,
      });
    }
  };

  const getEditRecipe = async (id) => {
    const editRecipe = await fetchRecipeToEdit(id);

    const recipe = editRecipe.recipe;

    if (recipe) {
      //Prefill non-state values
      //TODO: Expand sections for data if necessary
      prefillRecipeFields(recipe);
    }
  };

  useEffect(() => {
    //Check if is editing a recipe
    if (recipeid) {
      getEditRecipe(recipeid);
      //TO DO:
      //Check if can edit
    }

    if (startType) {
      if (startType === "generate") {
        if (plus.isPlus) {
          setFormView("sections");
          setInitialAddType("generate");
          if (startPrompt) {
            console.log(startPrompt);
            setInitialAddPrompt(startPrompt);
          }
          setShowAddSection(true);
        } else {
          togglePlusUpgradeModal(true);
        }
      }

      if (startType === "import") {
        setFormView("sections");
        setInitialAddType("import");
        setShowAddSection(true);
      }
    }

    //Reset state on exit
    return () => {
      imageUploadReset();
      resetCreateRecipe();
      //-> Reset recipe create state
    };
  }, [startType]);

  const infoView = (
    <div>
      <div className="flex flex-col h-full md:flex-row">
        <div className="w-full">
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            fullWidth
            className="mb-2"
          />
          <TextField
            label="Description"
            multiline
            minRows={7}
            maxRows={10}
            variant="outlined"
            value={description}
            onChange={handleDescriptionChange}
            fullWidth
          />
        </div>
        <div className="ml-0 mt-2 md:ml-2 md:mt-0">
          <ImageDropzone />
        </div>
      </div>

      <ImageReorderPreview className={images.length > 0 ? "my-2" : "my-1"} />
      <div className="flex flex-row justify-between w-full mb-8 [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
        <div className="w-1/2 space-y-2">
          <Searchable
            label="Cuisine"
            helperText="The recipe's cuisine, such as Italian or Mexican"
            labelWidth={70}
            multiple={false}
            freeSolo={true}
            apiUrl="/cuisines/searchable"
            allowAdd={true}
            onItemChange={onCuisineChange}
            selectedItemsFromParent={cuisine}
          />
        </div>

        <div className="w-1/2 space-y-2">
          <Searchable
            label="Course"
            helperText="The recipe's course, such as main or dessert"
            labelWidth={70}
            multiple={false}
            freeSolo={true}
            apiUrl="/recipe-courses/searchable"
            allowAdd={true}
            onItemChange={onCourseChange}
            selectedItemsFromParent={course}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="mb-2">
          <FormControl>
            <Select
              fullWidth
              value={recipeSourceType}
              onChange={(e) => setRecipeSourceType(e.target.value)}
              className={`${recipeSourceType === "Add a source" && "text-secondaryText"}`}
            >
              {recipeSourceType === "Add a source" ? (
                <MenuItem value={"Add a source"} className="text-secondaryText">
                  Add a Source
                </MenuItem>
              ) : (
                <MenuItem value={"Add a source"} className="text-secondaryText">
                  Remove Source
                </MenuItem>
              )}

              <MenuItem value={"Inspired by"}>Inspired by</MenuItem>
              <MenuItem value={"Adapted from"}>Adapted from</MenuItem>
              <MenuItem value={"Based on"}>Based on</MenuItem>
              <MenuItem value={"A take on"}>A take on</MenuItem>
              <MenuItem value={"Found in"}>Found in</MenuItem>
              <MenuItem value={"Handed down from"}>Handed down from</MenuItem>
              <MenuItem value={"Influenced by"}>Influenced by</MenuItem>
              <MenuItem value={"Generated"}>Generated</MenuItem>
              <MenuItem value={"Imported from"}>Imported from</MenuItem>
            </Select>
          </FormControl>
        </div>

        {recipeSourceType !== "Add a source" && (
          <>
            <div className="mb-2">
              <TextField
                label="Source"
                helperText="Where is this recipe from?"
                variant="outlined"
                fullWidth
                value={recipeSource}
                onChange={onRecipeSourceChange}
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Link"
                helperText="Add a link to the recipe source"
                variant="outlined"
                fullWidth
                value={recipeSourceURL}
                onChange={onRecipeSourceURLChange}
              />
            </div>
          </>
        )}
      </div>
      <div className="mt-8">
        <RecipeSectionTags tags={tags} setTags={setTags} />
      </div>
    </div>
  );

  const sectionsView = (
    <div>
      <div className="w-full my-4 md:mx-0">
        <RecipeSectionManager setShowAddSection={setShowAddSection} setInitialAddType={setInitialAddType} />
        <div>
          <Button variant="contained" disableElevation disableRipple>
            <Typography className="font-normal" variant="subtitle2" onClick={() => handleAddSections()}>
              Add a Section
            </Typography>
          </Button>
        </div>
      </div>
      <Divider />
      <div className="w-full space-y-2 my-8 md:m-4 md:mx-0">
        <Typography variant="subtitle1" className="mb-2 font-normal">
          Output
        </Typography>
        <div>
          <TextField
            label="Servings"
            variant="outlined"
            helperText="How many people does this recipe serve?"
            fullWidth
            value={servings}
            onChange={onServingsChange}
            inputProps={{
              inputMode: "numeric",
            }}
          />
        </div>
        {showYield ? (
          <>
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Recipe Yield
              </Typography>
            </div>
            <div className="space-y-2">
              <div className="flex flex-row w-full [&>*:first-child]:mr-0.5 [&>*:first-child]:w-1/2 [&>*:nth-child(2)]:ml-0.5 [&>*:nth-child(2)]:w-1/2">
                <div>
                  <TextField
                    label="Yield Amount"
                    variant="outlined"
                    helperText="How much does this make? Eg. 12, 1/2"
                    fullWidth
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    onChange={(e) => onYieldAmountChange(e)}
                    value={recipeYield.amount}
                  />
                </div>
                <div>
                  <Searchable
                    label="Yield Type/Unit"
                    helperText="What unit does this recipe make? Eg. cookies, ml, cups"
                    labelWidth={125}
                    multiple={false}
                    freeSolo={true}
                    apiUrl="/units/searchable"
                    allowAdd={true}
                    onItemChange={onYieldUnitChange}
                    fullWidth
                    selectedItemsFromParent={recipeYield.unit}
                  />
                </div>
              </div>

              <div>
                <Searchable
                  label="Yield Ingredient"
                  helperText="What does the recipe make?"
                  labelWidth={130}
                  multiple={false}
                  freeSolo={true}
                  apiUrl="/ingredients/searchable"
                  allowAdd={true}
                  onItemChange={onYieldIngredientChange}
                  selectedItemsFromParent={recipeYield.ingredient}
                />
              </div>
              <Button
                onClick={() => handleRemoveYield()}
                className="text-secondaryText hover:bg-transparent"
                disableRipple
              >
                <Typography>- Remove Yield</Typography>
              </Button>
            </div>
          </>
        ) : (
          <Button onClick={() => setShowYield(true)} className="text-secondaryText hover:bg-transparent" disableRipple>
            <Typography>+ Add Yield to track leftovers for this recipe</Typography>
          </Button>
        )}
      </div>
      <Divider />
      <div className="w-full space-y-2 my-8 md:mx-0">
        <Typography variant="subtitle1" className="mb-2 font-normal">
          Timing
        </Typography>
        <div>
          <div className="flex flex-row items-center [&>*:first-child]:mr-0.5 [&>*:nth-child(2)]:ml-0.5">
            <TextField
              variant="outlined"
              label="Hours"
              fullWidth
              helperText="Total Time"
              type="text"
              inputProps={{
                inputMode: "numeric",
              }}
              value={timing.total.hours}
              onChange={(event) => {
                const value = event.target.value;
                if (value < 0 || isNaN(value) || value.includes("e")) {
                  event.target.value = 0;
                }
                handleTimingChange("total", "hours", event.target.value);
              }}
              onBlur={() => handleTimingBlur("total")}
            />
            <TextField
              variant="outlined"
              label="Minutes"
              fullWidth
              helperText="Total Time"
              type="text"
              inputProps={{
                inputMode: "numeric",
              }}
              value={timing.total.minutes}
              onChange={(event) => {
                const value = event.target.value;
                if (value < 0 || isNaN(value) || value.includes("e")) {
                  event.target.value = 0;
                }
                handleTimingChange("total", "minutes", event.target.value);
              }}
              onBlur={() => handleTimingBlur("total")}
            />
          </div>
        </div>
        {showAdditionalTiming ? (
          <>
            <div>
              <div className="flex flex-row items-center [&>*:first-child]:mr-0.5 [&>*:nth-child(2)]:ml-0.5">
                <TextField
                  variant="outlined"
                  label="Hours"
                  fullWidth
                  helperText="Prep Time"
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  value={timing.prep.hours}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value < 0 || isNaN(value) || value.includes("e")) {
                      event.target.value = 0;
                    }
                    handleTimingChange("prep", "hours", event.target.value);
                  }}
                  onBlur={() => handleTimingBlur("prep")}
                />
                <TextField
                  variant="outlined"
                  label="Minutes"
                  fullWidth
                  helperText="Prep Time"
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  value={timing.prep.minutes}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value < 0 || isNaN(value) || value.includes("e")) {
                      event.target.value = 0;
                    }
                    handleTimingChange("prep", "minutes", event.target.value);
                  }}
                  onBlur={() => handleTimingBlur("prep")}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row items-center [&>*:first-child]:mr-0.5 [&>*:nth-child(2)]:ml-0.5">
                <TextField
                  variant="outlined"
                  label="Hours"
                  fullWidth
                  helperText="Cook Time"
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  value={timing.cook.hours}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value < 0 || isNaN(value) || value.includes("e")) {
                      event.target.value = 0;
                    }
                    handleTimingChange("cook", "hours", event.target.value);
                  }}
                  onBlur={() => handleTimingBlur("cook")}
                />
                <TextField
                  variant="outlined"
                  label="Minutes"
                  fullWidth
                  helperText="Cook Time"
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  value={timing.cook.minutes}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value < 0 || isNaN(value) || value.includes("e")) {
                      event.target.value = 0;
                    }
                    handleTimingChange("cook", "minutes", event.target.value);
                  }}
                  onBlur={() => handleTimingBlur("cook")}
                />
              </div>
            </div>
            <Button
              onClick={() => handleRemoveAdditionalTiming()}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">- Remove Prep and Cook Time</Typography>
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setShowAdditionalTiming(true)}
            className="text-secondaryText hover:bg-transparent"
            disableRipple
          >
            <Typography variant="subtitle1">+ Add Prep and Cook Time</Typography>
          </Button>
        )}
      </div>
    </div>
  );

  const nutritionView = (
    <div className="w-full space-y-2 md:m-4 md:mx-0">
      <div className="w-full">
        {showAddNutrition ? (
          <div className="my-4">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Macronutrients
              </Typography>
            </div>
            <div className="flex flex-row items-start w-full [&>*:first-child]:mr-0.5 [&>*:nth-child(2)]:ml-0.5">
              <div className="w-1/2 space-y-2">
                <TextField
                  variant="outlined"
                  label="Calories"
                  helperText="Calories in kcal"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("calories", e.target.value)}
                  value={nutrition.calories}
                />
                <TextField
                  variant="outlined"
                  label="Total Fat"
                  helperText="Total Fat in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("fat", e.target.value)}
                  value={nutrition.fat}
                />
                <TextField
                  variant="outlined"
                  label="Saturated Fat"
                  helperText="Saturated Fat in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("saturatedFat", e.target.value)}
                  value={nutrition.saturatedFat}
                />
                <TextField
                  variant="outlined"
                  label="Unsaturated Fat"
                  helperText="Unsaturated Fat in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("unsaturatedFat", e.target.value)}
                  value={nutrition.unsaturatedFat}
                />
                <TextField
                  variant="outlined"
                  label="Trans Fat"
                  helperText="Trans Fat in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("transFat", e.target.value)}
                  value={nutrition.transFat}
                />
                <TextField
                  variant="outlined"
                  label="Cholesterol"
                  helperText="Cholesterol in mg"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("cholesterol", e.target.value)}
                  value={nutrition.cholesterol}
                />
              </div>
              <div className="w-1/2 space-y-2">
                <TextField
                  variant="outlined"
                  label="Sodium"
                  helperText="Sodium in mg"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("sodium", e.target.value)}
                  value={nutrition.sodium}
                />
                <TextField
                  variant="outlined"
                  label="Carbohydrates"
                  helperText="Carbohydrates in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("carbohydrates", e.target.value)}
                  value={nutrition.carbohydrates}
                />
                <TextField
                  variant="outlined"
                  label="Fiber"
                  helperText="Fiber in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("fiber", e.target.value)}
                  value={nutrition.fiber}
                />

                <TextField
                  variant="outlined"
                  label="Protein"
                  helperText="Protein in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("protein", e.target.value)}
                  value={nutrition.protein}
                />
                <TextField
                  variant="outlined"
                  label="Total Sugars"
                  helperText="Total sugars in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("sugars", e.target.value)}
                  value={nutrition.sugars}
                />
                <TextField
                  variant="outlined"
                  label="Natural Sugars"
                  helperText="Naturally occurring sugars in g"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => handleNutritionChange("naturalSugars", e.target.value)}
                  value={nutrition.naturalSugars}
                />
              </div>
            </div>
            <Button
              onClick={() => handleRemoveMacros()}
              className="text-secondaryText hover:bg-transparent mt-4"
              disableRipple
            >
              <Typography variant="subtitle1">- Remove Macronutrients</Typography>
            </Button>
          </div>
        ) : (
          <div className="my-4">
            <Button
              onClick={() => setShowAddNutrition(true)}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">+ Add Macronutrients</Typography>
            </Button>
          </div>
        )}
        <Divider />
        {showAllergens ? (
          <div className="my-4">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Allergens
              </Typography>
            </div>
            <Searchable
              label="Contains"
              labelWidth={85}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenChange}
              helperText="Add any allergens this recipe contains, such as peanuts or soy"
              selectedItemsFromParent={allergens}
            />
            <Searchable
              label="May Contain"
              labelWidth={110}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenMayContainChange}
              helperText="Add any allergens this recipe may contain"
              selectedItemsFromParent={allergenMayContain}
            />
            <Button
              onClick={() => handleRemoveAllergens()}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">- Remove Allergens</Typography>
            </Button>
          </div>
        ) : (
          <div className="my-4">
            <Button
              onClick={() => setShowAllergens(true)}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">+ Add Allergens</Typography>
            </Button>
          </div>
        )}
        <Divider />
        {showFriendlyDiets ? (
          <div className="my-4">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Friendly Diets
              </Typography>
            </div>
            <Searchable
              label="Friendly Diets"
              labelWidth={115}
              multiple={true}
              freeSolo={true}
              apiUrl="/diets/searchable"
              allowAdd={true}
              onItemChange={onFriendlyDietChange}
              helperText="Add any friendly diets this recipe adheres to, such as vegan or gluten-free"
              selectedItemsFromParent={friendlyDiets}
            />
            <Button
              onClick={() => handleRemoveFriendlyDiets()}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">- Remove Friendly Diets</Typography>
            </Button>
          </div>
        ) : (
          <div className="my-4">
            <Button
              onClick={() => setShowFriendlyDiets(true)}
              className="text-secondaryText hover:bg-transparent"
              disableRipple
            >
              <Typography variant="subtitle1">+ Add Friendly Diets</Typography>
            </Button>
          </div>
        )}
      </div>
      {false && (
        <div className="flex flex-col mt-8">
          <Typography variant="h5" className="mb-2">
            Boost
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={boost} onClick={() => handleBoostChange(!boost)} color="primary" />}
            label={<Typography variant="subtitle1">Boost my recipe with AI</Typography>}
          />
          <div>
            {viewAIAreas ? (
              <Button
                className="py-1 text-secondaryText hover:bg-transparent"
                onClick={() => setViewAIAreas(false)}
                disableRipple
              >
                <FontAwesomeIcon icon={faChevronUp} />
                <Typography variant="subtitle1" className="ml-2">
                  Hide
                </Typography>
              </Button>
            ) : (
              <Button
                className="py-1 text-secondaryText hover:bg-transparent"
                onClick={() => setViewAIAreas(true)}
                disableRipple
              >
                <FontAwesomeIcon icon={faChevronDown} />
                <Typography variant="subtitle1" className="ml-2">
                  Toggle AI Boost Areas
                </Typography>
              </Button>
            )}
            {viewAIAreas && (
              <div className="ml-8 flex flex-col">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={generateNutrition}
                      onClick={() => setGenerateNutrition(!generateNutrition)}
                      color="secondary"
                    />
                  }
                  label={<Typography variant="subtitle1">Generate nutrition info</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={generateTips} onClick={() => setGenerateTips(!generateTips)} color="secondary" />
                  }
                  label={<Typography variant="subtitle1">Add tips to help improve the recipe</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={generateTags} onClick={() => setGenerateTags(!generateTags)} color="secondary" />
                  }
                  label={<Typography variant="subtitle1">Add additional recipe tags</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={generateTiming}
                      onClick={() => setGenerateTiming(!generateTiming)}
                      color="secondary"
                    />
                  }
                  label={
                    <Typography variant="subtitle1">Add additional timing information, such as resting time</Typography>
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>{edit.recipe ? `Edit ${edit.recipe.name}` : "Create Recipe"}</title>
      </Helmet>
      <CustomModal
        open={showAddSection}
        handleClose={() => setShowAddSection(false)}
        contentClassNameOverride="md:w-[70vw] md:max-w-[700px] lg:max-w-[600px]"
      >
        <AddRecipeSectionModal
          handleModalClose={() => handleAddSectionModalClose()}
          setRecipeFields={prefillRecipeFields}
          initialAddType={initialAddType}
          initialAddPrompt={initialAddPrompt}
        />
      </CustomModal>
      <CustomModal open={plus.modals.upgrade} handleClose={() => togglePlusUpgradeModal(false)} border={false}>
        <PlusModal handleClose={() => togglePlusUpgradeModal(false)} />
      </CustomModal>

      <div className="flex flex-col items-center mb-[4.5rem] mt-[4.5rem] mx-4  md:my-4 md:ml-[6rem] md:mr-4">
        <div className="max-w-[800px] w-full">
          <div className="flex flex-col items-center">
            <div className="w-full m-4 space-y-2 md:m-4 md:mx-0">
              <div className="w-full">
                <Tabs value={formView} onValueChange={(value) => setFormView(value)} className="w-full">
                  <TabsList className="w-full border border-separator p-0">
                    <TabsTrigger value="info" className="w-full h-full">
                      Info
                    </TabsTrigger>
                    <TabsTrigger value="sections" className="w-full h-full">
                      Sections
                    </TabsTrigger>
                    <TabsTrigger value="nutrition" className="w-full h-full">
                      Nutrition
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              {formView === "info" && infoView}
              {formView === "sections" && sectionsView}
              {formView === "nutrition" && nutritionView}
            </div>
            <Divider />
            <div className="flex w-full items-center justify-between mt-1">
              {edit.recipe ? (
                <Button
                  onClick={(e) => handleFormSubmit(e)}
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={true}
                >
                  Save Recipe
                </Button>
              ) : (
                <Button onClick={(e) => handleFormSubmit(e)} variant="contained" color="primary" disableElevation>
                  +&nbsp;Create Recipe
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateRecipe.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  imageUploadReset: PropTypes.func.isRequired,
  createRecipe: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fetchRecipeToEdit: PropTypes.func.isRequired,
  media: PropTypes.object.isRequired,
  resetCreateRecipe: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  user: state.auth.user,
  media: state.media,
  plus: state.plus,
});
export default connect(mapStateToProps, {
  processIngredients,
  setAlert,
  imageUploadReset,
  createRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  togglePlusUpgradeModal,
})(CreateRecipe);
