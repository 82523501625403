import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../api/api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//Assets
import CardImage from "./res/success/card.jpg";

//Icons
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef-sharp-light.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/svg/discord.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/svg/home.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/svg/arrow-down-to-line-light.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/svg/create.svg";
const stripePromise = loadStripe(
  "pk_test_51PKuT2EG8mQA0pyFza3a2NTP1WHbvb8AYmvb6JI79UdmQJx0xZm1XLqkbkrgMvSiQwp1edtOvJfG2wCHGrL8xFm200OTsmaHHe"
);

const SubscriptionSuccessPage = () => {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const location = useLocation();
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      setStatus("error");
      setError("No client secret found in URL");
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setStatus("success");
          break;
        case "processing":
          setStatus("processing");
          break;
        case "requires_payment_method":
          setStatus("error");
          setError("Payment failed. Please try another payment method.");
          break;
        default:
          setStatus("error");
          setError("Something went wrong.");
          break;
      }
    });
  }, [stripe, location.search]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error: {error}</div>;
  }

  if (status === "processing") {
    return <div>Payment processing. We'll update you when payment is received.</div>;
  }

  //TODO:
  //Generate recipe
  //Join discord

  return (
    <div className="w-full h-full xl:h-screen flex flex-col xl:flex-row xl:gap-16 justify-center items-center mx-auto py-[4rem] md:py-0 md:pl-[5rem] xl:pr-[4rem]">
      <div className="w-full xl:w-1/2 flex-shrink-0 flex-1 relative overflow-hidden h-full min-h-[500px]">
        <div
          className="absolute inset-0 bg-cover bg-center w-full h-full"
          style={{ backgroundImage: `url(${CardImage})` }}
        ></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40"></div>
        <div className="flex flex-col justify-end h-full px-4 py-8 md:px-8 md:py-12 relative z-10">
          <Typography variant="h3" className="mb-2 text-white font-medium">
            Welcome to<br></br>thisPantry<span className="text-mainGreen-300">+</span>!
          </Typography>
          <Typography variant="subtitle1" className="leading-snug text-secondaryText-100">
            Your subscription is now active!<br></br>Get ready to experience your kitchen of the future.
          </Typography>
        </div>
      </div>
      <div className="w-full xl:w-[25vw] flex flex-col gap-8 my-8 px-4 md:px-8 xl:px-0 xl:my-0">
        <div className="w-full">
          <Typography variant="h5" className="text-2xl xl:text-4xl">
            What's next?
          </Typography>
          <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
            Get the most out of your kitchen with these features:
          </Typography>
        </div>

        <div className="w-full flex flex-col gap-2">
          <NavigationOption
            icon={<GenerateIcon className="h-6 fill-mainGreen" />}
            title="Generate a Recipe"
            description="Create your next favorite meal with our AI-powered recipe generator."
            link="/create/recipe?type=generate"
          />

          <NavigationOption
            icon={<ImportIcon className="h-6 fill-mainGreen" />}
            title="Import a Recipe"
            description="Fill out your cookbook with your favorite recipes from around the web."
            link="/create/recipe?type=import"
          />
          <div className="w-full flex flex-row gap-4 p-4 items-center border border-separator shadow-sm rounded-xl">
            <div className="w-12 flex justify-center items-center">
              <DiscordIcon className="h-6 fill-discord"></DiscordIcon>
            </div>
            <a href="https://discord.gg/YyYNRzjK" target="_blank" rel="noopener noreferrer" className="w-full flex-1">
              <div>
                <div className="flex flex-row items-center gap-2">
                  <Typography variant="subtitle1" className="font-medium leading-none">
                    Join the Discord
                  </Typography>
                  <LinkIcon className="w-3 fill-secondaryText" />
                </div>
              </div>
            </a>
          </div>
          <NavigationOption
            icon={<HomeIcon className="h-6 fill-secondaryText" />}
            title="Home"
            description="Go back to the home page."
            link="/"
          />
        </div>
      </div>
    </div>
  );
};

const NavigationOption = ({ icon, title, description, link }) => {
  return (
    <div className="w-full flex flex-row  gap-4 p-4 items-center border border-separator shadow-sm rounded-xl">
      <div className="w-12 flex justify-center items-center">{icon}</div>
      <Link to={link} className="w-full flex-1">
        <div>
          <div className="flex flex-row items-center gap-2">
            <Typography variant="subtitle1" className="font-medium leading-none">
              {title}
            </Typography>
          </div>
        </div>
      </Link>
    </div>
  );
};

const SubscriptionSuccessWrapper = () => {
  return (
    <>
      <Helmet>
        <title>Welcome to thisPantry+!</title>
      </Helmet>
      <Elements stripe={stripePromise}>
        <SubscriptionSuccessPage />
      </Elements>
    </>
  );
};

export default SubscriptionSuccessWrapper;
