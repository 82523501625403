import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../api/api";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";

//Components
import CheckoutForm from "./CheckoutForm";
import CheckoutInfo from "./CheckoutInfo";
import Spinner from "../layout/Spinner";

//MUI
import Typography from "@mui/material/Typography";

const stripePromise = loadStripe(
  "pk_test_51PKuT2EG8mQA0pyFza3a2NTP1WHbvb8AYmvb6JI79UdmQJx0xZm1XLqkbkrgMvSiQwp1edtOvJfG2wCHGrL8xFm200OTsmaHHe"
);

const CheckoutPage = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretError, setClientSecretError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);

  const handleCheckoutSuccess = () => {
    setCheckoutSuccess(true);
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await api.post("/stripe/create-subscription");
        setClientSecret(response.data.clientSecret);
        setSubscriptionId(response.data.subscriptionId);
        setClientSecretError(false);
      } catch (error) {
        setClientSecretError(true);
        if (error.response && error.response.status === 409) {
          setErrorMessage("Oops! You are already subscribed to thisPantry+");
        } else {
          console.error("Error fetching client secret:", error);
          setErrorMessage("Oops! An error occurred. Please refresh the page and try again.");
        }
      }
    };

    if (clientSecret === "") {
      fetchClientSecret();
    }

    return () => {
      if (subscriptionId && !checkoutSuccess) {
        api.post("/stripe/cancel-checkout", { subscriptionId });
      }
      setClientSecretError(false);
    };
  }, [subscriptionId, checkoutSuccess]);

  if (clientSecretError && errorMessage !== "") {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Typography>{errorMessage}</Typography>
        <Link to="/" className="mt-2">
          <Button className="text-mainGreen hover:bg-transparent" disableRipple>
            <Typography variant="subtitle1">{"< Go back to Home"}</Typography>
          </Button>
        </Link>
      </div>
    );
  }

  if (!clientSecret) {
    return <Spinner />;
  }

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#598a56",
      colorBackground: "#ffffff",
      colorText: "#362e2d",
      colorDanger: "#ef4444",
      fontFamily: "'Poppins', 'Roboto', system-ui, sans-serif",
    },
    rules: {
      ".Input": {
        padding: "12px",
        border: "1px solid #e0e0e0",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
    },
  };

  const fonts = [
    {
      cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Upgrade to thisPantry+</title>
      </Helmet>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance,
          fonts,
        }}
      >
        <div className="py-[4rem] md:py-0 md:pl-[5rem] md:mx-8 md:my-8">
          <div className="w-full max-w[1400px] flex justify-center gap-12 flex-col lg:flex-row">
            <div className="w-full lg:max-w-[60%] xl:max-w-[40%]">
              <CheckoutInfo />
            </div>
            <CheckoutForm subscriptionId={subscriptionId} handleCheckoutSuccess={handleCheckoutSuccess} />
          </div>
        </div>
      </Elements>
    </>
  );
};

export default CheckoutPage;
